import React, {useContext, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import {notify} from "react-notify-toast";
import {AuthContext} from "../../shared/context/auth-context";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Modal from "../../shared/components/UIElements/Modal";
import Button from "../../shared/components/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import TodayIcon from '@material-ui/icons/Today';
import Avatar from '@material-ui/core/Avatar';
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import PhoneIcon from "@material-ui/icons/Phone";
import HouseIcon from "@material-ui/icons/House";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginLeft:40
    },

}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(131, 173, 159)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(131, 173, 159,0.3)",
        },
    },
}))(TableRow);

function SupplierTable(props) {
    const classes = useStyles();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const auth = useContext(AuthContext);
    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [deleteSupplierID, setDeleteSupplierID] = useState('');

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelDeleteHandler = () => {
        setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {

        setShowConfirmModal(false);
        try {
            await sendRequest(
                `${process.env.REACT_APP_URL}/supp/supplier/${deleteSupplierID}`,
                'DELETE',
                null,
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                }
            );
            props.onDelete(deleteSupplierID);
            notify.show('Supplier is deleted successfully', 'error', 4000);

        } catch (err) {
        }
    };

    return (

        <React.Fragment>
            <ErrorModal error={error} onClear={clearError}/>

            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                header="Are you sure?"
                footerClass="user-item__modal-actions"
                footer={
                    <React.Fragment>
                        <Button inverse onClick={cancelDeleteHandler}>
                            CANCEL
                        </Button>
                        <Button danger onClick={confirmDeleteHandler}>
                            DELETE
                        </Button>

                    </React.Fragment>
                }
            >
                <p>
                    Do you want to proceed and delete this Supplier? <br />
                    Please note that it can't be undone thereafter.
                </p>
            </Modal>
            <TableContainer component={Paper} elevation={8} square={true}>
                {isLoading && <LoadingSpinner asOverlay/>}
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Profile Avatar</StyledTableCell>
                            <StyledTableCell align="center"><PersonIcon/>&nbsp; Supplier ID</StyledTableCell>
                            <StyledTableCell align="center"><SentimentVerySatisfiedIcon/>&nbsp; Supplier Name</StyledTableCell>
                            <StyledTableCell align="center"><ShowChartIcon/>&nbsp; Type</StyledTableCell>
                            <StyledTableCell align="center"><PhoneIcon/>&nbsp; Telephone</StyledTableCell>
                            <StyledTableCell align="center"><HouseIcon/>&nbsp; Address</StyledTableCell>
                            <StyledTableCell align="center"><TimelapseIcon/>&nbsp; Status</StyledTableCell>
                            <StyledTableCell align="center"><TodayIcon/>&nbsp; Date Joined</StyledTableCell>
                            <StyledTableCell align="center"><SettingsIcon/>&nbsp; Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" align="left">
                                   <Avatar src={`${process.env.REACT_APP_URL}/${row.image}`} alt={row.name} className={classes.avatar}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.supplier_id}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">{row.type}</StyledTableCell>
                                <StyledTableCell align="center">{row.telephone_no}</StyledTableCell>
                                <StyledTableCell align="center">{row.address}</StyledTableCell>
                                <StyledTableCell align="center">{row.status}</StyledTableCell>
                                <StyledTableCell align="center">{row.date_joined}</StyledTableCell>
                                <StyledTableCell align="center"><IconButton aria-label="delete" component={Link}
                                                                           to={`/suppliers/${row.supplier_id}`}>
                                    <EditIcon/>
                                </IconButton><IconButton aria-label="delete" onClick={() => {
                                    setDeleteSupplierID( row.supplier_id);
                                    showDeleteWarningHandler();
                                }}>
                                    <DeleteIcon/>
                                </IconButton></StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>

    );
}

export default SupplierTable;