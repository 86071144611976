import React, { useEffect, useState } from 'react';
import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

const Users = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedUsers, setLoadedUsers] = useState();
    const [loadedAgent, setLoadedAgent] = useState([]);
    const [loadedOfficer, setLoadedOfficer] = useState([]);
    const [loadedManager, setLoadedManager] = useState([]);
    const [loadedAdmin, setLoadedAdmin] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/auth/users`
                );

                setLoadedUsers(responseData.users);

                setLoadedAgent(responseData.users);
                setLoadedAgent(prevState => prevState.filter(user => user.user_type === "Agent"));

                setLoadedOfficer(responseData.users);
                setLoadedOfficer(prevState => prevState.filter(user => user.user_type === "Officer"));

                setLoadedManager(responseData.users);
                setLoadedManager(prevState => prevState.filter(user => user.user_type === "Manager"));

                setLoadedAdmin(responseData.users);
                setLoadedAdmin(prevState => prevState.filter(user => user.user_type === "Admin"));

                console.log(responseData.users);
            } catch (err) { }
        };
        fetchUsers();
    }, [sendRequest]);


    const userAgentDeletedHandler = deletedUserId => {
        setLoadedAgent(prevUsers =>
            prevUsers.filter(user => user.user_id !== deletedUserId)
        );
    };
    const userOfficerDeletedHandler = deletedUserId => {
        setLoadedOfficer(prevUsers =>
            prevUsers.filter(user => user.user_id !== deletedUserId)
        );
    };
    const userManagerDeletedHandler = deletedUserId => {
        setLoadedManager(prevUsers =>
            prevUsers.filter(user => user.user_id !== deletedUserId)
        );
    };
    const userAdminDeletedHandler = deletedUserId => {
        setLoadedAdmin(prevUsers =>
            prevUsers.filter(user => user.user_id !== deletedUserId)
        );
    };

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}

            {!isLoading && loadedUsers && <UsersList items={loadedUsers}
                agent={loadedAgent}
                officer={loadedOfficer}
                manager={loadedManager}
                admin={loadedAdmin}
                onDeleteAgent={userAgentDeletedHandler}
                onDeleteOfficer={userOfficerDeletedHandler}
                onDeleteManager={userManagerDeletedHandler}
                onDeleteAdmin={userAdminDeletedHandler} />}

        </React.Fragment>
    );
};

export default Users;
