import React, {useEffect, useState} from 'react';
import Card from "../../shared/components/UIElements/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import VarianceReport from "../components/VarianceReport";
import {useHttpClient} from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import makeStyles from "@material-ui/core/styles/makeStyles";
import background from '../../assets/white_back_e.jpg';

const useStyles = makeStyles(theme => ({

    background: {
        marginTop:0,
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "150vh",
        width: "100%"
    },
    typoMargin: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },
        maxWidth: "70em",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "0 1px 3px rgba(0, 0, 0)",
        borderRadius: "6px",
        background: "rgb(0, 0, 0, 0.1)",
        maxHeight: "5em"
    },
}));

function Variance() {
    const classes = useStyles();
    const [reports, setReports] = useState([]);
    const {isLoading, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchVarianceReport = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/diff/reports/dreports`
                );
                setReports(responseData.dreports);
    
            } catch (err) {
            }
        };
        fetchVarianceReport();
    }, [sendRequest]);


    if (reports.length === 0) {
        return (
            <div className={classes.background}>
                <Card className="center">
                    <h2>No Reports found.</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.background}>
                <div className={classes.typoMargin}>

                    <Typography variant={"h3"} align={"center"}>
                        Variance Report
                    </Typography>

                </div>
                {isLoading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!isLoading && reports && <Grid container spacing={1} direction={"column"} alignItems={"center"}>
                    {reports.map((report) => (
                        <VarianceReport
                            key={report.report_id}
                            date={report.date}
                            originalW={report.original_weight}
                            remeasuringW={report.remeasuring_weight}
                            weightDiff={report.weight_difference}
                            suppID={report.supplier_id}
                            name={report.supplier_name}
                            officerID={report.officer_id}
                            AgentID={report.agent_id}
                        />
                    ))}

                </Grid>}
            </div>
        </React.Fragment>
    );
}


export default Variance;