import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import { VALIDATOR_REQUIRE } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import './MachineForm.css';
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import qs from 'query-string';
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.9",
    },

}));

const UpdateMachine = (props) => {
    const parsed = qs.parse(window.location.search);
    const auth = useContext(AuthContext);
    const id = useParams().id;
    const type = useParams().type;
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedMachine, setLoadedMachine] = useState();
    const classes = useStyles();
    const history = useHistory();

    const [formState, inputHandler, setFormData] = useForm(
        {
            id: {
                value: '',
                isValid: false
            },
            modal: {
                value: '',
                isValid: false
            },
            power_info: {
                value: '',
                isValid: false
            },
        },
        false
    );


    useEffect(() => {
        const fetchMachine = async () => {
            console.log(type);

            // console.log(parsed);
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/machine/machine?id=${id}&type=${type}`
                );
                console.log(responseData.machine);

                setLoadedMachine(responseData.machine[0]);
                setFormData(
                    {
                        id: {
                            value: responseData.machine[0].id,
                            isValid: true
                        },
                        modal: {
                            value: responseData.machine[0].modal,
                            isValid: true
                        },
                        machine_purchase_date: {
                            value: responseData.machine[0].machine_purchase_date,
                            isValid: true
                        },
                        power_info: {
                            value: responseData.machine[0].power_info,
                            isValid: true
                        },
                    },
                    true
                );
                setStartDate(new Date(responseData.supplier[0].machine_purchase_date));

            } catch (err) {
            }
        };
        fetchMachine();

    }, [sendRequest, setFormData, parsed.machine_id, parsed.type]);

    const machineUpdateSubmitHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(
                `${process.env.REACT_APP_URL}/machine/machines/${parsed.type}`,
                'PATCH',
                JSON.stringify({
                    machine_id: formState.inputs.id.value,
                    modal: formState.inputs.modal.value,
                    machine_purchase_date: startDate, // formState.inputs.machine_purchase_date.value
                    power_info: formState.inputs.power_info.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                }
            );
            history.push('/machines');
        } catch (err) {
        }
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedMachine && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find supplier!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && loadedMachine && (
                <Grid container alignItems={"center"} justify={"center"} className={classes.background}
                    direction={"column"}>
                    <Grid item>
                        <form className="machine-update-form" onSubmit={machineUpdateSubmitHandler}>
                            <h2 style={{ color: "grey", textAlign: "center" }}>Edit Machine</h2>
                            <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Input
                                    id="modal"
                                    element="input"
                                    label="Modal"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a valid modal."
                                    onInput={inputHandler}
                                    initialValue={loadedMachine.modal}
                                    initialValid={true}
                                />
                                <Grid item>
                                    <Input
                                        id="power_info"
                                        element="input"
                                        label="Power Information"
                                        validators={[VALIDATOR_REQUIRE()]}
                                        errorText="Please enter a valid information."
                                        onInput={inputHandler}
                                        initialValue={loadedMachine.power_info}
                                        initialValid={true}
                                    />
                                </Grid> </Grid>
                            <Grid container direction={"column"} justify={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <div style={{ color: "black", fontWeight: "boldest", marginBottom: "1rem",  }}>
                                        <Typography style={{display: "inline-block"}} variant={'h6'}>Machine Purchased Date : &nbsp;</Typography>
                                        <DatePicker style={{display: "inline-block"}} selected={startDate} onChange={date => setStartDate(date)} />
                                    </div>
                                    
                                </Grid>
                                <Grid item>
                                    <div style={{ marginTop: "3rem" }}>
                                        <Button type="submit" disabled={!formState.isValid}>
                                            UPDATE MACHINE
                                    </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>

            )}

        </React.Fragment>
    );
};

export default UpdateMachine;
