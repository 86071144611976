import React, { useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Input from "../shared/components/FormElements/Input";
import {
    VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH, VALIDATOR_PASSWORD, VALIDATOR_PHONE, VALIDATOR_EMAIL
} from "../shared/util/validators";
import Button from "../shared/components/FormElements/Button";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useForm } from "../shared/hooks/form-hook";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import Grid from "@material-ui/core/Grid";
import background from "../assets/bg16.jpg";
import "./AuthNew.css";
import ImageUpload from "../shared/components/FormElements/ImageUpload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import './AuthForm.css';

const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%"
    },
}));

const AuthNew = () => {
    const classes = useStyles();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [startDate, setStartDate] = useState(new Date());

    const history = useHistory();

    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            telephone_no: {
                value: '',
                isValid: false
            },
            nic: {
                value: '',
                isValid: false
            },
            address: {
                value: '',
                isValid: false
            },

            image: {
                value: null,
                isValid: false
            },
            email: {
                value: '',
                isValid: false
            },
            password: {
                value: '',
                isValid: false
            },
            confirm_password: {
                value: '',
                isValid: false
            },
        }, false
    );

    const authSubmitHandler = async event => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('email', formState.inputs.email.value); //email);
            formData.append('name', formState.inputs.name.value);
            formData.append('password', formState.inputs.password.value); //password);
            formData.append('dob', startDate);
            formData.append('user_type', "Manager");
            formData.append('telephone_no', formState.inputs.telephone_no.value);
            formData.append('nic', formState.inputs.nic.value);
            formData.append('address', formState.inputs.address.value);
            formData.append('image', formState.inputs.image.value);
            const responseData = await sendRequest(`${process.env.REACT_APP_URL}/auth/signupbeforeconfirm`, 'PUT', formData,);
            console.log(responseData);
            if (responseData.message === 'Email is sent to admin') {
                history.push('/auth');
            }
        } catch (err) {
        }
    };

    return (
        <React.Fragment>
            <Grid container alignItems={"center"} justify={"center"} direction={"column"}
                className={classes.background}>
                <Grid item >
                    <ErrorModal error={error} onClear={clearError} />
                    <form className="auth-form-signup" onSubmit={authSubmitHandler}>
                        {isLoading && <LoadingSpinner asOverlay />}
                        <h2 style={{ color: "grey", textAlign: "center" }}>Register</h2>
                        <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    id="name"
                                    element="input"
                                    label="Name :"
                                    type="text"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a valid name."
                                    onInput={inputHandler}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="email"
                                    element="input"
                                    label="Email :"
                                    type="text"
                                    validators={[VALIDATOR_EMAIL()]}
                                    errorText="Please enter a valid email address."
                                    onInput={inputHandler}
                                /></Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    element="input"
                                    id="password"
                                    type="password"
                                    label="Password :"
                                    validators={[VALIDATOR_MINLENGTH(4)]}
                                    errorText="Please enter a valid password, at least 4 characters."
                                    onInput={inputHandler}
                                /></Grid>
                            <Grid item>
                                <Input
                                    element="input"
                                    id="confirm_password"
                                    type="password"
                                    label="Confirm Password :"
                                    validators={[VALIDATOR_PASSWORD(formState.inputs.password.value)]}
                                    errorText="Please confirm password."
                                    onInput={inputHandler}
                                /></Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    id="telephone_no"
                                    element="input"
                                    label="Telephone Number :"
                                    type="text"
                                    validators={[VALIDATOR_PHONE()]}
                                    errorText="Please enter a valid telephone number."
                                    onInput={inputHandler}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="nic"
                                    element="input"
                                    label="NIC :"
                                    type="text"
                                    validators={[VALIDATOR_MAXLENGTH(10), VALIDATOR_MINLENGTH(10)]}
                                    errorText="Please enter a valid NIC."
                                    onInput={inputHandler}
                                />
                            </Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                            <ImageUpload
                            id="image"
                            onInput={inputHandler}
                        />
                            </Grid>
                            <Grid item style={{ marginRight: "2.3rem" }}>

                                <div style={{ color: "black", fontWeight: "Boldest", marginBottom: "0.5rem", }}>
                                    <Typography variant={'h6'}>D.O.B : </Typography>
                                </div>
                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                            </Grid></Grid>
                        <Input
                            id="address"
                            element="textarea"
                            label="Address :"
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="Please enter a valid address, at least 5 characters."
                            onInput={inputHandler}
                        />
                        
                        < Button type="submit" disabled={!formState.isValid}>
                            SIGNUP
                            </Button>
                        <br /><br />
                        <br /><br />
                        <Button onClick={() => history.push('/auth')}>
                            SWITCH TO LOGIN
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AuthNew;
