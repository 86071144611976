import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ProductionReport from "./ProductionReport";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({

    tableContainer:{
        marginBottom:"5em"
    }
//

});

function Output(props) {
    const classes = useStyles();
    return (
        
        <React.Fragment>
            <Grid item >
                <Typography variant={'h4'}>Output</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.output && <ProductionReport items={props.output}
                                                          header={['Batch No', 'Grade GL', 'Outturn', 'Date']}/>}
            </Grid>
        </React.Fragment>
    );
}

export default Output;