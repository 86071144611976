import React, { useContext, useEffect, useState } from 'react';
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import makeStyles from "@material-ui/core/styles/makeStyles";
import backg from "../../assets/white_back_e.jpg";
import { AuthContext } from "../../shared/context/auth-context";
import qs from "query-string";
import './SupplierSelection.css';
import ProductionReport from "../components/ProductionReport";



const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${backg})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y",
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
    },
    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "20em",
        background: " rgb(0, 0, 0, 0.3)"
    },
    root: {
        marginLeft: "5em",
        marginRight: "5em",
    },
    supplierProfileItemContent: {
        display: 'flex',
        color: 'black',
        marginTop: '3%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '15%',
        padding: '1rem',
        maxWidth: '20rem',
        minHeight: '10rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 2px 8px rgba(0, 0, 0)',
        borderRadius: '6px',
        '& label': {
            color: 'black',
        },
        '& select': {
            borderBottom: "2px solid black",
        }

    }
}));


const SupplierDetails = () => {
    const classes = useStyles();
    const auth = useContext(AuthContext);
    const parsed = qs.parse(window.location.search);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedSupplierData, setLoadedSupplierData] = useState();

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/reports/supplier/${parsed.supplier_id}/Daily`, 'GET', null, {
                    Authorization: 'Bearer ' + auth.token
                }
                );
                console.log(responseData.supplier);
                let merge = [];
                for (let i = 0; i < responseData.supplier.length; i++) {
                    merge.push({
                        supplier_id: parsed.supplier_id,
                        name: parsed.name,
                        type: parsed.type,
                        'A': responseData.supplier[i].A,
                        'B': responseData.supplier[i].B,
                        'C': responseData.supplier[i].C,
                        'date': responseData.supplier[i].date,
                    });

                }

                setLoadedSupplierData(merge);

            } catch (err) {
            }
        };

        fetchSuppliers();
    }, [sendRequest, auth.token, parsed.name, parsed.supplier_id, parsed.type]);

 // This function is developed for getting supplier's purchasing yearly and monthly but backend function is not developed.
    // const supplierDropdownHandler = async (event) => {
    //     event.preventDefault();
    //     console.log(formState.inputs);
    //     try {
    //         const responseData = await sendRequest(
    //             `${process.env.REACT_APP_URL}/supp/reports/supplier/${parsed.supplier_id}/${formState.inputs.type.value}`, 'GET', null, {
    //             Authorization: 'Bearer ' + auth.token
    //         }
    //         );
    //         let merge = [];
    //         for (let i = 0; i < responseData.supplier.length; i++) {
    //             merge.push({
    //                 supplier_id: parsed.supplier_id,
    //                 name: parsed.name,
    //                 type: parsed.type,
    //                 'A': responseData.supplier[i].A,
    //                 'B': responseData.supplier[i].B,
    //                 'C': responseData.supplier[i].C,
    //                 'date': responseData.supplier[i].date,
    //             });

    //         }
    //         setLoadedSupplierData(merge);


    //     } catch (err) {
    //     }
    // };


    return (
        <Grid className={classes.background}
            direction={"column"}>
            <Grid container direction={"column"} spacing={3}>
                <ErrorModal error={error} onClear={clearError} />

                <Grid item>
                    <Typography align={'center'} variant={'h2'}>Purchasing</Typography>
                </Grid>

                <Grid item style={{ marginLeft: "5rem", marginRight: "5rem", minWidth: "20rem", minHeight: "40rem", marginTop: "5rem"}}>
                    {isLoading && (
                        <div className="center">
                            <LoadingSpinner />
                        </div>
                    )}
                    {!isLoading && loadedSupplierData && <ProductionReport items={loadedSupplierData}
                        header={['ID', 'Supplier Name', 'Type', 'Leaf Grade A (Kg)', 'Leaf Grade B (Kg)', 'Leaf Grade C (Kg)', 'Date']} />}
                </Grid>

            </Grid>
        </Grid>

    );
};

export default SupplierDetails;
