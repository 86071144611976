import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import DropdownMenu from '../Navigation/DropdownMenu';
import './NavLinks.css';
import DropdownLogout from "./DropdownLogout";

const NavLinks = props => {
  const auth = useContext(AuthContext);

  return (
    <ul className="nav-links">
        {auth.isLoggedIn &&(
            <li>
                <NavLink to="/" exact>
                    DASHBOARD
                </NavLink>
            </li>
        )}
      {auth.isLoggedIn && (
          <li>
            <DropdownMenu links={[{link:"/reports/production",linkName:'Production'},{link:"/reports/purchasing",linkName:'Purchasing'},{link:"/reports/variance",linkName:'Variance'},{link:"/reports/agentpurchasing",linkName:'Agent Purchasing'}]} buttonName="Reports"/>
          </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <DropdownMenu links={[{link:"/users",linkName:'View Users'},{link:"/users/new",linkName:'Add Users'}]} buttonName="Users"/>
        </li>
      )}

      {auth.isLoggedIn && (
          <li>
            <DropdownMenu links={[{link:"/suppliers",linkName:'View Suppliers'},{link:"/suppliers/new",linkName:'Add Suppliers'}]} buttonName="Suppliers"/>
          </li>
      )}

      {auth.isLoggedIn && (
          <li>
            <DropdownMenu links={[{link:"/machines",linkName:'View Machines'},{link:"/machines/new",linkName:'Add Machines'},{link:"/trough/new",linkName:'Add Troughs'}]} buttonName="Machines"/>
          </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <DropdownLogout/>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
