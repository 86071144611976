import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import Button from "../shared/components/FormElements/Button";
import CardContent from "@material-ui/core/CardContent";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Input from "../shared/components/FormElements/Input";
import { VALIDATOR_MINLENGTH, VALIDATOR_PASSWORD, VALIDATOR_EMAIL } from "../shared/util/validators";
import { useHttpClient } from "../shared/hooks/http-hook";
import makeStyles from "@material-ui/core/styles/makeStyles";
import background from "../assets/bg16.jpg";
import { useForm } from "../shared/hooks/form-hook";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%"
    },
    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "10em",
        background: "rgba(240, 255, 240, 0.8)"
    }
}));
const AuthForget = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler] = useForm(
        {
            email: {
                value: '',
                isValid: false
            },
            password: {
                value: '',
                isValid: false
            }
        },
        false
    );
    const authRESETHandler = async event => {
        event.preventDefault();
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/auth/resetPassword/${formState.inputs.email.value}`,
                'PATCH',
                JSON.stringify({
                    password: formState.inputs.password.value
                }),
                {
                    'Content-Type': 'application/json'
                }
            );
            if (responseData.success) {
                history.push('/users');
            }

        } catch (err) {
        }
    };

    return (
        <Grid container alignItems={"center"} justify={"center"} >

            <Card className={classes.card}>
                <ErrorModal error={error} onClear={clearError} />

                <CardContent>
                    {isLoading && <LoadingSpinner asOverlay />}
                    <h2 style={{ color: "grey", textAlign: "center" }}>Reset Password</h2>
                    <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                    <form onSubmit={authRESETHandler}>
                        <Input
                            element="input"
                            id="email"
                            type="text"
                            label="Email :"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email address."
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="password"
                            type="password"
                            label="Password :"
                            validators={[VALIDATOR_MINLENGTH(4)]}
                            errorText="Please enter a valid password, at least 4 characters."
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="confirm_password"
                            type="password"
                            label="Confirm Password :"
                            validators={[VALIDATOR_PASSWORD(formState.inputs.password.value)]}
                            errorText="Please confirm password."
                            onInput={inputHandler}
                        />

                        <Button type="submit" disabled={!formState.isValid}>
                            RESET
                        </Button>
                    </form>
                </CardContent>

            </Card>
            <div className={classes.background} />
        </Grid>
    );
}

export default AuthForget;