import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProductionReport from "./ProductionReport";

const useStyles = makeStyles({

    tableContainer:{
        marginBottom:"5em"
    }
//

});

const  LoftRoom = (props)=> {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid item>
                <Typography variant={'h4'}>Loft Room (Loading)</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.loading && <ProductionReport items={props.loading}
                                                          header={['Trough No', 'Box No', 'Leaf Grade', 'Net Weight (Kg)', 'Date']}/>}
            </Grid>
            <Grid item>
                <Typography variant={'h4'}>Loft Room (Starting)</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.starting && <ProductionReport items={props.starting}
                                                          header={['Trough No', 'Temperature (°C)', 'Humidity (g/Kg)','Date','Time']}/>}
            </Grid>
            <Grid item>
                <Typography variant={'h4'}>Loft Room (Mixing)</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.mixing && <ProductionReport items={props.mixing}
                                                          header={['Trough No','Turn','Temperature (°C)', 'Humidity (g/Kg)','Date','Time']}/>}
            </Grid>
            <Grid item>
                <Typography variant={'h4'}>Loft Room (Finishing)</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.finishing && <ProductionReport items={props.finishing}
                                                          header={['Trough No', 'Temperature (°C)', 'Humidity (g/Kg)','Date','Time']}/>}
            </Grid>
            <Grid item>
                <Typography variant={'h4'}>Loft Room (Unloading)</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.unloading && <ProductionReport items={props.unloading}
                                                          header={['Trough No', 'Box No', 'Leaf Grade', 'Net Weight (Kg)', 'Withering %','Batch No','Date']}/>}
            </Grid>
        </React.Fragment>
    );
}

export default LoftRoom;