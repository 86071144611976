import { createMuiTheme } from '@material-ui/core/styles';

const green = "#388e3c";
const yellow = "#f8df00";
const theme = createMuiTheme({
    palette: {
        common:{
            green,
            yellow
        },
        primary: {
            main: green,
        },
        secondary: {
            main: yellow,
        },
    },
    typography:{
        h3:{
            fontFamily:"Raleway",
            fontWeight:900,
            fontSize:"2.5rem",
            color:"white",
            lineHeight:1.5
        },
    }
});

export default theme;