import React from 'react';
import Card from "../../shared/components/UIElements/Card";

import Grid from "@material-ui/core/Grid";
import "./VarianceReport.css"

function VarianceReport(props) {
   
    return (
        
            <Grid item style={{ textAlign: "center" }}>
                <div className="variance-item">
                    <Card className="variance-item__content">
                        <div className="variance-item__actions_above">
                            <h2>{props.date}</h2>

                        </div>
                        <div className="variance-item__info">

                            <Grid container justify="space-evenly"
                                alignItems="center" spacing={2}>
                                <Grid item style = {{minWidth: "250px"}}>
                                    <Grid container direction={"column"} spacing={4} >
                                        <Grid item >

                                            <h3>
                                                Supplier ID: <strong>{props.suppID}</strong>
                                            </h3>


                                        </Grid>
                                        <Grid item>
                                            <h3>
                                                Agent ID: <strong>{props.AgentID}</strong>
                                            </h3>
                                        </Grid>
                                        <Grid item>
                                            <h3>
                                                Officer ID: <strong>{props.officerID}</strong>
                                            </h3>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item style = {{minWidth: "300px"}}>
                                    <Grid container direction={"column"} spacing={4}>
                                        <Grid item>
                                            <h3>
                                                Supplier Name: <strong>{props.name}</strong>
                                            </h3>
                                        </Grid>
                                        <Grid item>
                                            <h3>
                                                Original Weight: <strong>{props.originalW} KG</strong>
                                            </h3>
                                        </Grid>
                                        <Grid item>
                                            <h3>
                                                Remeasuring Weight: <strong>{props.remeasuringW} KG</strong>
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </div>
                        <div className="variance-item__actions">
                            <div><h2>Weight Difference: {props.weightDiff} KG</h2></div>

                        </div>
                    </Card>
                </div>
            </Grid>
       
    );
}

export default VarianceReport;