import React from 'react';
import TroughItem from './TroughItem';
import DrierItem from './DrierItem';
import RollerItem from './RollerItem';
import RollBreakerItem from './RollBreakerItem';
import Card from '../../shared/components/UIElements/Card';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import './MachineList.css';


const useStyles = makeStyles(theme => ({
    typoMargin: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },

        maxWidth: "80em",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2em",
        boxShadow: "0 1px 3px rgba(0, 0, 0)",
        borderRadius: "6px",
        background: "rgba(240, 255, 240, 0.8)",
        maxHeight: "5em",
    },
     machineContainer: {
        marginLeft: "2em",
        marginRight: "2em",
        
        [theme.breakpoints.down("md")]: {
            marginLeft: "1em",
            marginRight: "1em",

      }

    }
  }
));

const MachineList = props => {
      const classes = useStyles();
    if (props.drier_item.length === 0 && props.trough_item.length === 0 && props.rb_items.length === 0 && props.roll_item.length === 0) {
        return (
            <div className="center">
                <Card>
                    <h2>No machines found.</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
              <div className='machineContainer-background'> 
              <div className={classes.machineContainer}>
                <div className={classes.typoMargin}>

                <Typography variant={"h3"} align={"center"} color={"primary"}>
                    Driers
                </Typography>
            </div>

            <Grid container spacing={1}>
                
                {props.drier_item.map((drier) => (
                    <DrierItem
                        key={drier.drier_id}
                        id={drier.drier_id}
                        image={drier.image}
                        modal={drier.modal}
                        machine_purchase_date={drier.machine_purchase_date}
                        power_info={drier.power_info}
                        onDelete={props.onDeleteDrier}
                        type={'Drier'}
                    />
                ))}

            </Grid>


                <div className={classes.typoMargin}>
                <Typography variant={"h3"} align={"center"} color={"primary"}>
                    Rollers
                </Typography>
      </div>
            <Grid container spacing={1}>

                {props.roll_item.map(roll_item => (
                    <RollerItem
                        key={roll_item.roller_id}
                        id={roll_item.roller_id}
                        image={roll_item.image}
                        modal={roll_item.modal}
                        machine_purchase_date={roll_item.machine_purchase_date}
                        power_info={roll_item.power_info}
                        onDelete={props.onDeleteRoller}
                        type={'Roller'}
                    />
                ))}
            </Grid>
  

                <div className={classes.typoMargin}>
                <Typography variant={"h3"} align={"center"} color={"primary"}>
                    Roll Breakers
                </Typography>
                </div>
            <Grid container spacing={1}>
                
                {props.rb_items.map(rb_items => (
                    <RollBreakerItem
                        key={rb_items.roll_breaker_id}
                        id={rb_items.roll_breaker_id}
                        image={rb_items.image}
                        modal={rb_items.modal}
                        machine_purchase_date={rb_items.machine_purchase_date}
                        power_info={rb_items.power_info}
                        onDelete={props.onDeleteRB}
                        type={'Roll Breaker'}
                    />
                ))}
            </Grid>
    

                <div className={classes.typoMargin}>
                <Typography variant={"h3"} align={"center"} color={"primary"}>
                   Troughs
                </Typography>
                </div>
            <Grid container spacing={1}>

                {props.trough_item.map(trough_item => (
                    <TroughItem
                        key={trough_item.trough_id}
                        id={trough_item.trough_id}
                        image={trough_item.image}
                        troughtype={trough_item.type}
                        capacity={trough_item.capacity}
                        onDelete={props.onDeleteTrough}
                        type={'Trough'}
                    />
                ))}
            </Grid>
        </div>
    </div>
</React.Fragment>

    );
};

export default MachineList;
