import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import {
    VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH, VALIDATOR_PASSWORD, VALIDATOR_PHONE, VALIDATOR_EMAIL
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './UserForm.css';
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    paperContainer: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.8"
    },
    back: {
        backgroundColor: "rgba(180, 235, 52, 0.6)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.95"
    },
}));

const NewUser = () => {
    const auth = useContext(AuthContext);
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const classes = useStyles();
    const history = useHistory();
    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            email: {
                value: '',
                isValid: false
            },
            password: {
                value: '',
                isValid: false
            },
            confirm_password: {
                value: '',
                isValid: false
            },
            user_type: {
                value: '',
                isValid: false
            },
            telephone_no: {
                value: '',
                isValid: false
            },
            nic: {
                value: '',
                isValid: false
            },
            address: {
                value: '',
                isValid: false
            }
        },
        false
    );

    const userSubmitHandler = async event => {
        event.preventDefault();

        try {
            const formData = new FormData();
            formData.append('name', formState.inputs.name.value);
            formData.append('email', formState.inputs.email.value);
            formData.append('password', formState.inputs.password.value);
            formData.append('dob', startDate); // formState.inputs.dob.value
            formData.append('user_type', formState.inputs.user_type.value);
            formData.append('telephone_no', formState.inputs.telephone_no.value);
            formData.append('nic', formState.inputs.nic.value);
            formData.append('address', formState.inputs.address.value);
            formData.append('image', formState.inputs.image.value);
            await sendRequest(`${process.env.REACT_APP_URL}/auth/signup`, 'PUT', formData, {
                Authorization: 'Bearer ' + auth.token
            });
            history.push('/users');
        } catch (err) {
        } // send this to the backend!
    };

    return (
        <React.Fragment>
            <Grid container alignItems={"center"} justify={"center"} direction={"column"}
                className={classes.paperContainer}>
                <Grid item>
                    <ErrorModal error={error} onClear={clearError} />
                    <form className="user-form" onSubmit={userSubmitHandler}>
                        {isLoading && <LoadingSpinner asOverlay />}
                        <h2 style={{ color: "grey", textAlign: "center" }}>Register New User</h2>
                        <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    id="name"
                                    element="input"
                                    label="Name :"
                                    type="text"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a valid name."
                                    onInput={inputHandler}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="email"
                                    element="input"
                                    label="Email :"
                                    type="text"
                                    validators={[VALIDATOR_EMAIL()]}
                                    errorText="Please enter a valid email address."
                                    onInput={inputHandler}
                                /></Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    element="input"
                                    id="password"
                                    type="password"
                                    label="Password :"
                                    validators={[VALIDATOR_MINLENGTH(4)]}
                                    errorText="Please enter a valid password, at least 4 characters."
                                    onInput={inputHandler}
                                /></Grid>
                            <Grid item>
                                <Input
                                    element="input"
                                    id="confirm_password"
                                    type="password"
                                    label="Confirm Password :"
                                    validators={[VALIDATOR_PASSWORD(formState.inputs.password.value)]}
                                    errorText="Please confirm password."
                                    onInput={inputHandler}
                                /></Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    id="telephone_no"
                                    element="input"
                                    label="Telephone Number :"
                                    type="text"
                                    validators={[VALIDATOR_PHONE()]}
                                    errorText="Please enter a valid telephone number."
                                    onInput={inputHandler}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="nic"
                                    element="input"
                                    label="NIC :"
                                    type="text"
                                    validators={[VALIDATOR_MAXLENGTH(10), VALIDATOR_MINLENGTH(10)]}
                                    errorText="Please enter a valid NIC."
                                    onInput={inputHandler}
                                />
                            </Grid></Grid>
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input
                                    id="user_type" element="dropdown" label="User Type :" onInput={inputHandler}
                                    dropdownItems={["Officer", "Agent"]} validators={[]}
                                />
                            </Grid>
                            <Grid item style={{ marginRight: "2.3rem" }}>

                                <div style={{ color: "black", fontWeight: "Boldest", marginBottom: "0.5rem" }}>
                                    <Typography variant={'h6'}>D.O.B : </Typography>
                                </div>
                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                            </Grid></Grid>
                        <Input
                            id="address"
                            element="textarea"
                            label="Address :"
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="Please enter a valid address, at least 5 characters."
                            onInput={inputHandler}
                        />
                        <Grid container justify={"space-between"} direction={"column"} alignItems={"center"}>
                            <Grid item>
                                <ImageUpload
                                    id="image"
                                    onInput={inputHandler}
                                />
                                <div style={{marginLeft:"2.325rem"}}>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        ADD USER
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default NewUser;
