import React from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from "./shared/util/theme";
import Users from './users/pages/Users';
import Dashboard from "./dashboard/pages/Dashboard";
import NewUser from "./users/pages/NewUser";
import UpdateUser from "./users/pages/UpdateUser";
import Production from "./reports/pages/Production";
import AuthNew from "./auth/AuthNew";
import MainNavigation from './shared/components/Navigation/MainNavigation';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from "./shared/hooks/auth-hook";
import Suppliers from "./suppliers/pages/Suppliers";
import NewSupplier from "./suppliers/pages/NewSupplier";
import UpdateSupplier from "./suppliers/pages/UpdateSupplier";
import Machines from "./machines/pages/Machines";
import NewMachine from "./machines/pages/NewMachine";
import UpdateMachine from "./machines/pages/UpdateMachine";
import Purchasing from "./reports/pages/Purchasing";
import UserProfile from "./users/pages/UserProfile";
import SupplierDetails from "./reports/pages/SupplierDetails";
import Notifications from 'react-notify-toast';
import AuthForget from "./auth/AuthForget";
import NewTrough from "./machines/pages/NewTrough";
import Variance from "./reports/pages/Variance";
import UpdateTrough from './machines/pages/UpdateTrough';
import AuthSignup from "./auth/AuthSignup";
import AgentPurchasing from "./reports/pages/AgentPurchasing";

const App = () => {
    const { token, login, logout, userId, userType } = useAuth();

    let routes;

    if (token) {
        routes = (
            <Switch>
                <Route path="/" exact>
                    <Dashboard />
                </Route>
                <Route path="/reports/production" exact>
                    <Production />
                </Route>
                <Route path="/reports/purchasing" exact>
                    <Purchasing />
                </Route>
                <Route path="/reports/variance" exact>
                    <Variance />
                </Route>
                <Route path="/reports/agentpurchasing" exact>
                    <AgentPurchasing />
                </Route>
                <Route path="/reports/purchasing/supplierlots" exact>
                    <SupplierDetails />
                </Route>
                <Route path="/users/userprofile" exact>
                    <UserProfile />
                </Route>
                <Route path="/users/new" exact>
                    <NewUser />
                </Route>
                <Route path="/users" exact>
                    <Users />
                </Route>
                <Route path="/users/:userId">
                    <UpdateUser />
                </Route>
                <Route path="/suppliers" exact>
                    <Suppliers />
                </Route>
                <Route path="/suppliers/new" exact>
                    <NewSupplier />
                </Route>
                <Route path="/suppliers/:suppId">
                    <UpdateSupplier />
                </Route>
                <Route path="/machines" exact>
                    <Machines />
                </Route>
                <Route path="/machines/new" exact>
                    <NewMachine />
                </Route>
                <Route path="/trough/new" exact>
                    <NewTrough />
                </Route>
                <Route path="/machines/edit/:id/:type">
                    <UpdateMachine />
                </Route>
                <Route path="/trough/edit/:id/:type">
                    <UpdateTrough />
                </Route>
                <Redirect to="/" />
            </Switch>
        );
    } else {
        routes = (
            <Switch>
                <Route path="/auth/resetpassword">
                    <AuthForget />
                </Route>
                <Route path="/auth">
                    <AuthNew />
                </Route>
                <Route path="/signup">
                    <AuthSignup />
                </Route>
                <Redirect to="/auth" />
            </Switch>
        );
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout,
                userType: userType
            }}
        >
            <ThemeProvider theme={theme}>
                <Router>
                    <Notifications />
                    <MainNavigation />
                    <main>{routes}</main>
                </Router>
            </ThemeProvider>
        </AuthContext.Provider>
        // </div>
    );
};

export default App;
