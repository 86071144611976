import React, { useContext, useState } from 'react';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import Input from "../shared/components/FormElements/Input";
import { VALIDATOR_MINLENGTH, VALIDATOR_EMAIL } from "../shared/util/validators";
import Button from "../shared/components/FormElements/Button";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useForm } from "../shared/hooks/form-hook";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import Grid from "@material-ui/core/Grid";
import background from "../assets/bg16.jpg";
import Modal from "../shared/components/UIElements/Modal";
import TextField from "@material-ui/core/TextField";
import "./AuthNew.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%"
    },
    card: {
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "0.8em 2em",
        width: "30em",
        background: "rgba(240, 255, 240, 0.8)"
    }
}));

const AuthNew = () => {

    const classes = useStyles();
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [email, setEmail] = useState();
    const [formState, inputHandler] = useForm(
        {
            email: {
                value: '',
                isValid: false
            },
            password: {
                value: '',
                isValid: false
            }
        },
        false
    );

    const history = useHistory();

    const showForgetPWHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelEmailHandler = () => {
        setShowConfirmModal(false);
    };

    const sendEmailHandler = async () => {
        setShowConfirmModal(false);
        try {
            console.log(email);
            await sendRequest(
                `${process.env.REACT_APP_URL}/auth/forgetpassword/${email}`,
                'GET',
                null,
                {
                    'Content-Type': 'application/json',
                }
            );
        } catch (err) {
        }
    };

    const authSubmitHandler = async event => {
        event.preventDefault();

        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/auth/loginWeb`,
                'POST',
                JSON.stringify({
                    email: formState.inputs.email.value,
                    password: formState.inputs.password.value
                }),
                {
                    'Content-Type': 'application/json'
                }
            );

            console.log(responseData.userType);
            auth.login(responseData.userId, responseData.token, null, responseData.userType);

        } catch (err) {

        }
    };

    return (
        <Grid container alignItems={"center"} justify={"center"} className={classes.background}>
            <Grid item>
                <Card className={classes.card}>
                    <ErrorModal error={error} onClear={clearError} />
                    <Modal
                        show={showConfirmModal}
                        onCancel={cancelEmailHandler}
                        header="Forget Password?"
                        footerClass="user-item__modal-actions"
                        footer={
                            <React.Fragment>
                                <Button inverse onClick={cancelEmailHandler}>
                                    CANCEL
                                </Button>
                                <Button danger onClick={sendEmailHandler}>
                                    SEND
                                </Button>
                            </React.Fragment>
                        }
                    >

                        <TextField id="standard-basic" label="Enter your email :"
                            onChange={(e) => setEmail(e.target.value)} />
                    </Modal>

                    <CardContent>
                        {isLoading && <LoadingSpinner asOverlay />}
                        <h2 style={{ color: "grey", textAlign: "center" }}>Login Required</h2>
                        <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                        <form className="auth-form" onSubmit={authSubmitHandler}>
                            <Input
                                element="input"
                                id="email"
                                type="text"
                                label="Email :"
                                validators={[VALIDATOR_EMAIL()]}
                                errorText="Please enter a valid email address."
                                onInput={inputHandler}
                            />

                            <Input
                                element="input"
                                id="password"
                                type="password"
                                label="Password :"
                                validators={[VALIDATOR_MINLENGTH(4)]}
                                errorText="Please enter a valid password, at least 4 characters."
                                onInput={inputHandler}
                            />

                            < Button type="submit" disabled={!formState.isValid}>
                                LOGIN
                            </Button>
                            <br /><br />
                            <a onClick={showForgetPWHandler}> <b>Forget Password ?</b></a>
                        </form>

                        <br /><br />
                        <Button onClick={() => history.push('/signup')}>
                            SWITCH TO SIGNUP
                        </Button>
                    </CardContent>

                </Card>
            </Grid>

        </Grid>
    );
};

export default AuthNew;
