import React, { useContext } from 'react';
import UserItem from './UserItem';
import UserCard from './UserCard';
import Card from '../../shared/components/UIElements/Card';
import Grid from '@material-ui/core/Grid';
import './UsersList.css';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles(theme => ({
    typoMargin: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },
        maxWidth: "30em",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2em",
        boxShadow: "0 1px 3px rgba(0, 0, 0)",
        borderRadius: "6px",
        background: "rgba(240, 255, 240, 0.8)",
        maxHeight: "5em",
        color: "green",
    },
    UserContainer: {
        marginLeft: "5em",
        marginRight: "5em",
        [theme.breakpoints.down("md")]: {
            marginLeft: "1em",
            marginRight: "1em",
        }
    }

}
));
const UsersList = props => {
    const auth = useContext(AuthContext);
    const classes = useStyles();

    if (props.items.length === 0) {
        return (
            <div className="center">
                <Card>
                    <h2>No users found.</h2>
                </Card>
            </div>
        );
    }

    return (

        <div className='UserContainer-background'>
            <div className={classes.UserContainer}>
                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Administrators
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    {props.admin.map(user => (
                        <UserCard
                            key={user.user_id}
                            id={user.user_id}
                            image={user.image}
                            name={user.name}
                            nic={user.nic}
                            telephone_no={user.telephone_no}
                            user_type={user.user_type}
                            email={user.email}
                            address={user.address}
                            dob={user.dob}
                            onDelete={props.onDeleteAdmin}
                        />
                    ))}
                </Grid>
                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Managers
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    {props.manager.map(user => (
                        auth.userType === "Admin" ?
                            <UserItem
                                key={user.user_id}
                                id={user.user_id}
                                image={user.image}
                                name={user.name}
                                nic={user.nic}
                                telephone_no={user.telephone_no}
                                user_type={user.user_type}
                                email={user.email}
                                address={user.address}
                                dob={user.dob}
                                onDelete={props.onDeleteManager}
                            /> :
                            <UserCard
                                key={user.user_id}
                                id={user.user_id}
                                image={user.image}
                                name={user.name}
                                nic={user.nic}
                                telephone_no={user.telephone_no}
                                user_type={user.user_type}
                                email={user.email}
                                address={user.address}
                                dob={user.dob}
                                onDelete={props.onDeleteManager}
                            />
                    ))}

                </Grid>

                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Factory Officers
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    {props.officer.map(user => (
                        <UserItem
                            key={user.user_id}
                            id={user.user_id}
                            image={user.image}
                            name={user.name}
                            nic={user.nic}
                            telephone_no={user.telephone_no}
                            user_type={user.user_type}
                            email={user.email}
                            address={user.address}
                            dob={user.dob}
                            onDelete={props.onDeleteOfficer}
                        />
                    ))}
                </Grid>
                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Field Agents
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    {props.agent.map(user => (
                        <UserItem
                            key={user.user_id}
                            id={user.user_id}
                            image={user.image}
                            name={user.name}
                            nic={user.nic}
                            telephone_no={user.telephone_no}
                            user_type={user.user_type}
                            email={user.email}
                            address={user.address}
                            dob={user.dob}
                            onDelete={props.onDeleteAgent}
                        />
                    ))}
                </Grid>

            </div>
        </div>
    );
};

export default UsersList;
