import React, { useEffect, useState } from 'react';
import SuppliersList from "../components/SupplierList";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const Suppliers = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedSuppliers, setLoadedSuppliers] = useState();
    const [loadedGrowerDirect, setLoadedGrowerDirect] = useState([]);
    const [loadedGrowerAgent, setLoadedGrowerAgent] = useState([]);
    const [loadedDealer, setLoadedDealer] = useState([]);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/suppliers`
                );

                setLoadedSuppliers(responseData.suppliers);
                setLoadedGrowerDirect(responseData.suppliers);
                setLoadedGrowerDirect(prevState => prevState.filter(supp => supp.type === "Grower Direct"));
                setLoadedGrowerAgent(responseData.suppliers);
                setLoadedGrowerAgent(prevState => prevState.filter(supp => supp.type === "Grower through Agent"));
                setLoadedDealer(responseData.suppliers);
                setLoadedDealer(prevState => prevState.filter(supp => supp.type === "Dealer"));
                console.log(responseData.suppliers);
            } catch (err) { }
        };
        fetchSuppliers();
    }, [sendRequest]);

    const supplierDirectDeletedHandler = deletedSupplierId => {
        setLoadedGrowerDirect(prevUsers =>
            prevUsers.filter(supp => supp.supplier_id !== deletedSupplierId)
        );
    };
    const supplierAgentDeletedHandler = deletedSupplierId => {
        setLoadedGrowerAgent(prevUsers =>
            prevUsers.filter(supp => supp.supplier_id !== deletedSupplierId)
        );
    };
    const supplierDealerDeletedHandler = deletedSupplierId => {
        setLoadedDealer(prevUsers =>
            prevUsers.filter(supp => supp.supplier_id !== deletedSupplierId)
        );
    };

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && loadedSuppliers && <SuppliersList items={loadedSuppliers} direct={loadedGrowerDirect} agent={loadedGrowerAgent} dealer={loadedDealer} onDeleteDirectSupplier={supplierDirectDeletedHandler} onDeleteAgentSupplier={supplierAgentDeletedHandler} onDeleteDealerSupplier={supplierDealerDeletedHandler} />}
        </React.Fragment>
    )
};

export default Suppliers;
