import React from 'react';
import Avatar from '../../shared/components/UIElements/Avatar';
import Card from '../../shared/components/UIElements/Card';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './UserItem.css';
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import './UserItem.css';
import { CardContent } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import HomeIcon from '@material-ui/icons/Home';
import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
        backgroundColor: blue,
    },

    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "20em",
        background: " rgb(0, 0, 0, 0.3)",
    },

    avatar: {
        backgroundColor: green[200],
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

}));

const UserCard = props => {
    const classes = useStyles();
    const { isLoading, error, clearError } = useHttpClient();

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <div className="user-item">
                    <div className={classes.background} />

                    <Card className="user-item__content">
                        {isLoading && <LoadingSpinner asOverlay />}

                        <div className="user-item__image">
                            {props.image ? <Avatar image={`${process.env.REACT_APP_URL}/${props.image}`} alt={props.name} /> :
                                <Avatar image={props.image} alt={props.name} />}
                        </div>
                        <CardContent>
                            <div className="user-item__info">
                                <h1 className='name'>{props.name}</h1>
                                <h2 className='id'><AccountCircleIcon /> {props.id}</h2>
                                <h3 className='type'><StarsRoundedIcon /> &nbsp;{props.user_type}</h3>
                                <p className='nic'><span><SubtitlesIcon /> &nbsp; {props.nic}</span></p>
                                <p className='telehone'><span><PhoneIcon />&nbsp; &nbsp;{props.telephone_no}</span></p>
                                <p className='nic'><span><TodayIcon /> &nbsp; {props.dob}</span></p>
                                <p className='address'><span><AlternateEmailIcon /> &nbsp; {props.email}</span></p>
                                <p className='address'><span><HomeIcon /> &nbsp; {props.address}</span></p>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </React.Fragment>
    );
};

export default UserCard;
