import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Leaf from "@material-ui/icons/KingBed";
import Outturn from "@material-ui/icons/ShowChart";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowFoward from "@material-ui/icons/ArrowForward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer";
import Danger from "../components/Typo/Danger";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import styles from "../style/dashboardStyle";
import "../pages/Dashboard.css";
import PieChart from "../components/PieChart";
import BarChart from "../components/BarChart";
import BarChartROllerWise from '../components/BarChartRollerWise';
import LineChart from "../components/LineChart";
import { useHttpClient } from "../../shared/hooks/http-hook";



const useStyles = makeStyles(styles);

const Dashboard = () => {
    const classes = useStyles();
    const { sendRequest } = useHttpClient();
    const [supplierLotsFirstWeek, setSupplierLotsFirstWeek] = useState([]);
    const [supplierLotsSecondWeek, setSupplierLotsSecondWeek] = useState([]);
    const [supplierLotsThirdWeek, setSupplierLotsThirdWeek] = useState([]);
    const [supplierLotsFourthWeek, setSupplierLotsFourthWeek] = useState([]);
    const [dhoolPctAvg, setDhoolPctAvg] = useState([]);
    const [rollerWiseDhoolPctAvg, setRollerWiseDhoolPctAvg] = useState([]);
    const [fermentedDhoolPctAvg, setFermentedDhoolPctAvg] = useState([]);
    const [todayPurchasedTea, setTodayPurchasedTea] = useState([]);
    const [todayMadeTea, setTodayMadeTea] = useState([]);
    const [todayAverageOutturn, setTodayAverageOutturn] = useState([]);
    const [suppliersUntilToday, setSuppliersUntilToday] = useState([]);
    const [totalOutturns, setTotalOutturns] = useState([]);
    const [TODates, setTODates] = useState([]);


    useEffect(() => {

        const fetchDhoolPctAvg = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/dailydhoolpct`
                );
    
                setDhoolPctAvg(responseData.dhools);
    
            } catch (err) {
            }
        };

        const fetchLotsFirstWeek = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/bleaf/dashboard/supplierlotsfirstweek`
                );
    
                setSupplierLotsFirstWeek(responseData.lots);
    
            } catch (err) {
            }
        };
    
        const fetchLotsSecondWeek = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/bleaf/dashboard/supplierlotssecondweek`
                );
    
                setSupplierLotsSecondWeek(responseData.lots);
    
            } catch (err) {
            }
        };
    
        const fetchLotsThirdWeek = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/bleaf/dashboard/supplierlotsthirdweek`
                );
    
                setSupplierLotsThirdWeek(responseData.lots);
    
            } catch (err) {
            }
        };
    
        const fetchLotsFourthWeek = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/bleaf/dashboard/supplierlotsfourthweek`
                );
    
                setSupplierLotsFourthWeek(responseData.lots);
    
            } catch (err) {
            }
        };
    
    
    
        const fetchFermentedDhoolPctAvg = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/dailyfermenteddhoolpct`
                );
    
                setFermentedDhoolPctAvg(responseData.fdDhools);
    
            } catch (err) {
            }
        };
    
        const fetchRollerWiseDhoolPctAvg = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/rollerwisedhoolpct`
                );
    
                setRollerWiseDhoolPctAvg(responseData.dhools);
    
            } catch (err) {
            }
        };
    
        const fetchTodayPurchasedTea = async () => {
    
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/bleaf/dashboard/todayPurchasedTea`
                );
    
                setTodayPurchasedTea(responseData.lots);
    
            } catch (err) {
            }
        };
    
        const fetchTodayMadeTea = async () => {
    
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/todayTotalMadeTea`
                );
    
                setTodayMadeTea(responseData.dhools);
    
            } catch (err) {
            }
        };
    
        const fetchTodayAverageOutturn = async () => {
    
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/todayoutturn`
                );
    
                setTodayAverageOutturn(responseData.out);
    
            } catch (err) {
            }
        };
    
        const fetchSuppliersUntilToday = async () => {
    
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/dashboard/suppliersuntiltoday`
                );
    
                setSuppliersUntilToday(responseData.suppliers);
    
            } catch (err) {
            }
        };
    
    
        const fetchTotalOutturn = async () => {
    
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/dashboard/totaloutturn`
                );
    
                setTODates(responseData.dates);
                setTotalOutturns(responseData.averageOutterns);
    
            } catch (err) {
            }
        };

        fetchLotsFirstWeek();
        fetchLotsSecondWeek();
        fetchLotsThirdWeek();
        fetchLotsFourthWeek();
        fetchDhoolPctAvg();
        fetchFermentedDhoolPctAvg();
        fetchTodayPurchasedTea();
        fetchTodayMadeTea();
        fetchTodayAverageOutturn();
        fetchSuppliersUntilToday();
        fetchRollerWiseDhoolPctAvg();
        fetchTotalOutturn();
    }, [sendRequest]);

    

    function getCurrentDate(separator = ' - ') {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
    }

    function sevenDaysAgo(separator = ' - ') {

        let newDate = new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
    }

    function fourteenDaysAgo(separator = ' - ') {

        let newDate = new Date(new Date() - 14 * 24 * 60 * 60 * 1000)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
    }

    function twentyOneDaysAgo(separator = ' - ') {

        let newDate = new Date(new Date() - 21 * 24 * 60 * 60 * 1000)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
    }

    function thirtyDaysAgo(separator = ' - ') {

        let newDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
    }

    return (
        <div className={classes.background}>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Leaf />
                            </CardIcon>
                            <p className={classes.cardCategory}>Purchased Tea</p>
                            <h3 className={classes.cardTitle}>
                                {todayPurchasedTea} <small>Kg</small>
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <DateRange />
                                </Danger>
                                For Today
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Store />
                            </CardIcon>
                            <p className={classes.cardCategory}>Total Made Tea</p>
                            <h3 className={classes.cardTitle}>{todayMadeTea} Kg</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <DateRange />
                                </Danger>
                                For Today
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Outturn />
                            </CardIcon>
                            <p className={classes.cardCategory}>Total Outturn</p>
                            <h3 className={classes.cardTitle}>{todayAverageOutturn} %</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <DateRange />
                                </Danger>
                                For Today
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Accessibility />
                            </CardIcon>
                            <p className={classes.cardCategory}>Suppliers</p>
                            <h3 className={classes.cardTitle}>{suppliersUntilToday}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <DateRange />
                                </Danger>
                                Up to Today
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            {/*--------------------------------Purchasing--------------------------------------*/}

            <hr className="col" />
            <p className="topic">Purchasing</p>

            <hr className="col" />
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader color="success">
                            <PieChart data={supplierLotsFirstWeek} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Suppliers' Contribution From Last Week</h4>
                            <p className={classes.cardCategory}>
                                <span className={classes.successText}>
                                    <DateRange />
                                </span>{" "}
                                {getCurrentDate()} <ArrowFoward /> {sevenDaysAgo()}
                            </p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 4 minutes ago ...!
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader color="warning">
                            <PieChart data={supplierLotsSecondWeek} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Suppliers' Contribution From Second Last Week</h4>
                            <p className={classes.cardCategory}>{sevenDaysAgo()} <ArrowFoward /> {fourteenDaysAgo()}</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 4 minutes ago ...!
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader color="danger">
                            <PieChart data={supplierLotsThirdWeek} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Suppliers' Contribution From Third Last Week</h4>
                            <p className={classes.cardCategory}>{fourteenDaysAgo()} <ArrowFoward /> {twentyOneDaysAgo()}
                            </p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 4 minutes ago ...!
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Card chart>
                        <CardHeader color="success">
                            <PieChart data={supplierLotsFourthWeek} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Suppliers' Contribution From Fourth Last Week</h4>
                            <p className={classes.cardCategory}>{twentyOneDaysAgo()} <ArrowFoward /> {thirtyDaysAgo()}
                            </p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 4 minutes ago ...!
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <hr className="col" />

            {/*--------------------------------Production------------------------------------------*/}

            <p className="topic">Production</p>
            <hr className="col" />
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                        <CardHeader color="success">
                            <BarChart data={dhoolPctAvg} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Leaf Grade wise Dhool % Average</h4>
                            <p className={classes.cardCategory}>
                                <span className={classes.successText}>
                                    <ArrowUpward className={classes.upArrowCardCategory} />
                                </span>{" "}
                                For the past seven days.
                            </p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 1 minute ago ...
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                        <CardHeader color="warning">
                            <BarChart data={fermentedDhoolPctAvg} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Leaf Grade wise Fermented Dhool % Average </h4>
                            <p className={classes.cardCategory}> <span className={classes.successText}>
                                <ArrowUpward className={classes.upArrowCardCategory} />
                            </span>{" "}For the past seven days.</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 1 minute ago ...
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                        <CardHeader color="warning">
                            <BarChartROllerWise data={rollerWiseDhoolPctAvg} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Roller Wise Dhool % Average</h4>
                            <p className={classes.cardCategory}><span className={classes.successText}>
                                <ArrowUpward className={classes.upArrowCardCategory} />
                            </span>{" "}For the past seven days.</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 1 minute ago ...
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card chart>
                        <CardHeader color="info">
                            <LineChart data={TODates} outturns={totalOutturns} />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Daily Outturn</h4>
                            <p className={classes.cardCategory}><span className={classes.successText}>
                                <ArrowUpward className={classes.upArrowCardCategory} />
                            </span>{" "}For the past seven days.</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime />
                                Updated 1 minute ago ...
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default Dashboard;
