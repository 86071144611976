import React, { useContext, useState } from 'react';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import { VALIDATOR_REQUIRE } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { AuthContext } from "../../shared/context/auth-context";
import { useHistory } from "react-router-dom";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";
import './MachineForm.css';
import DatePicker from "react-datepicker";
import Typography from '@material-ui/core/Typography';
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.9",
    },
}));

const NewMachine = () => {
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler] = useForm(
        {
            modal: {
                value: '',
                isValid: false
            },
            power_info: {
                value: '',
                isValid: false
            },
            type: {
                value: '',
                isValid: false
            },
        },
        false
    );

    const history = useHistory();

    const machineSubmitHandler = async event => {
        event.preventDefault();
        console.log(formState.inputs); // send this to the backend!
        try {
            const formData = new FormData();

            formData.append('modal', formState.inputs.modal.value);
            formData.append('machine_purchase_date', startDate); // formState.inputs.machine_purchase_date.value
            formData.append('power_info', formState.inputs.power_info.value);
            formData.append('type', formState.inputs.type.value);
            formData.append('image', formState.inputs.image.value);

            await sendRequest(`${process.env.REACT_APP_URL}/machine/machine`, 'POST', formData, {
                Authorization: 'Bearer ' + auth.token
            });
            history.push('/machines');

        } catch (err) {
        } // send this to the backend!
    };

    return (

        <Grid container alignItems={"center"} justify={"center"} className={classes.background} direction={"column"}>
            <Grid item>

                <ErrorModal error={error} onClear={clearError} />
                <form className="machine-form" onSubmit={machineSubmitHandler}>
                    {isLoading && <LoadingSpinner asOverlay />}
                    <h2 style={{ color: "grey", textAlign: "center" }}>Register New Machine</h2>
                    <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                    <Grid container justify={"space-between"} alignItems={"baseline"}>
                        <Grid item>
                            <Input
                                id="modal"
                                element="input"
                                label="Model :"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid modal."
                                onInput={inputHandler}
                            />
                            <Input
                                id="power_info"
                                element="input"
                                label="Power Information :"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid information."
                                onInput={inputHandler}
                            />

                            <div style={{ color: "black", fontWeight: "boldest", marginBottom: "1rem" }}><Typography variant={'h6'}>Machine Purchased
                        Date : </Typography></div>

                            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />

                        </Grid>
                        <Grid item>
                            <Input
                                id="type"
                                element="dropdown"
                                label="Machine Type : "
                                dropdownItems={["Roller", "Roll Breaker", "Drier"]}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid type."
                                onInput={inputHandler}
                            />
                            <ImageUpload
                                id="image"
                                onInput={inputHandler}
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" disabled={!formState.isValid}>
                        ADD MACHINE
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
};

export default NewMachine;
