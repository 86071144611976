import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from "@material-ui/core/Link";
import { useHttpClient } from "../../shared/hooks/http-hook";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoftRoom from "../components/LoftRoom";
import Rolling from "../components/Rolling";
import RollBreaking from "../components/RollBreaking";
import Fermenting from "../components/Fermenting";
import Drying from "../components/Drying";
import Output from '../components/Output';
import Button from '../../shared/components/FormElements/Button';
import DatePicker from "react-datepicker";
import './SupplierSelection.css';
import "react-datepicker/dist/react-datepicker.css";
import backg from '../../assets/white_back_e.jpg';

const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${backg})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y",
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
    },
    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "20em",
        background: " rgb(0, 0, 0, 0.3)"
    },
    root: {
        marginLeft: "15em",
        marginRight: "15em",
    },
    breadcrumbsContent: {
        display: 'flex',
        color: 'black',
        marginTop: '2%',
        marginLeft: "auto",
        marginRight: 'auto',
        marginBottom: '5%',
        padding: '1em',
        minWidth: '40rem',
        minHeight: '5em',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 8px rgba(0, 0, 0)',
        borderRadius: '6px',
        background: " rgb(0, 255, 0, 0.1)",
        fontWeight: "500"
    },

}));

const Production = () => {
    const classes = useStyles();
   
    const [startDate, setStartDate] = useState(new Date());
    const [selectedStage, setSelectedStage] = useState('Loft');
    const [loftRoomLoading, setLoftRoomLoading] = useState([]);
    const [loftRoomStarting, setLoftRoomStarting] = useState([]);
    const [loftRoomMixing, setLoftRoomMixing] = useState([]);
    const [loftRoomFinishing, setLoftRoomFinishing] = useState([]);
    const [loftRoomUnloading, setLoftRoomUnloading] = useState([]);
    const [rolling, setRolling] = useState([]);
    const [rollBreaking, setRollBreaking] = useState([]);
    const [fermenting, setFermenting] = useState([]);
    const [drying, setDrying] = useState([]);
    const [output, setOutput] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {

        fetchLoftRoomLoading();
        fetchLoftRoomStarting();
        fetchLoftRoomMixing();
        fetchLoftRoomFinishing();
        fetchLoftRoomUnloading();
    }, [sendRequest]);


    const handleClick = async (event, type) => {
        event.preventDefault();
        console.info(type);
        switch (type) {
            case 'Loft':
                await fetchLoftRoomLoading();
                await fetchLoftRoomStarting();
                await fetchLoftRoomMixing();
                await fetchLoftRoomFinishing();
                await fetchLoftRoomUnloading();
                setSelectedStage('Loft');
                return;
            case 'Rolling':
                await fetchRolling();
                setSelectedStage('Rolling');
                return;
            case 'RBreaking':
                await fetchRollBreaking();
                setSelectedStage('RBreaking');
                return;
            case 'Fermenting':
                await fetchFermenting();
                setSelectedStage('Fermenting');
                return;
            case 'Drying':
                await fetchDrying();
                setSelectedStage('Drying');
                return;
            case 'Output':
                await fetchOutput();
                setSelectedStage('Output');
                return;
            default:
                return;
        }
    };

    const fetchLoftRoomLoading = async () => {
        let loading = {};
        let loadingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/loft/reports/loading`
            );

            for (let i = 0; i < responseData.loading.length; i++) {
                loading = {
                    trough_no: responseData.loading[i].BoxBoxId[1],
                    box_no: responseData.loading[i].BoxBoxId[3],
                    grade_GL: responseData.loading[i].grade_GL,
                    net_weight: responseData.loading[i].total_Net_weight,
                    date: responseData.loading[i].date,
                }

                loadingArray.push(loading);
            }

            setLoftRoomLoading(loadingArray);

        } catch (err) {
        }
    };
    const fetchLoftRoomStarting = async () => {
        let starting = {};
        let startingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/loft/reports/starting`
            );

            for (let i = 0; i < responseData.starting.length; i++) {
                starting = {
                    trough_no: responseData.starting[i].TroughTroughId,
                    temp: responseData.starting[i].temperature,
                    humidity: responseData.starting[i].humidity,
                    date: responseData.starting[i].date.slice(0, 10),
                    time: responseData.starting[i].date.slice(11, 19),
                }
                startingArray.push(starting);
            }
            setLoftRoomStarting(startingArray);
        } catch (err) {
        }
    };
    const fetchLoftRoomMixing = async () => {
        let mixing = {};
        let mixingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/loft/reports/mixing`
            );

            for (let i = 0; i < responseData.mixing.length; i++) {
                mixing = {
                    trough_no: responseData.mixing[i].TroughTroughId,
                    turn: responseData.mixing[i].ProcessProcessName.slice(6),
                    temp: responseData.mixing[i].temperature,
                    humidity: responseData.mixing[i].humidity,
                    date: responseData.mixing[i].date.slice(0, 10),
                    time: responseData.mixing[i].date.slice(11, 19),
                }

                mixingArray.push(mixing);
            }
            setLoftRoomMixing(mixingArray);

        } catch (err) {
        }
    };
    const fetchLoftRoomFinishing = async () => {
        let finishing = {};
        let finishingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/loft/reports/finishing`
            );
            console.log(responseData.finishing);

            for (let i = 0; i < responseData.finishing.length; i++) {
                finishing = {
                    trough_no: responseData.finishing[i].TroughTroughId,
                    temp: responseData.finishing[i].temperature,
                    humidity: responseData.finishing[i].humidity,
                    date: responseData.finishing[i].date.slice(0, 10),
                    time: responseData.finishing[i].date.slice(11, 19),
                }
                finishingArray.push(finishing);
            }
            setLoftRoomFinishing(finishingArray);

        } catch (err) {
        }
    };
    const fetchLoftRoomUnloading = async () => {
        let unloading;
        let unloadingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/loft/reports/unloading`
            );
            console.log(responseData.unloading);
            for (let i = 0; i < responseData.unloading.length; i++) {
                unloading = {
                    trough_no: responseData.unloading[i].box_id[1],
                    box_no: responseData.unloading[i].box_id[3],
                    grade_GL: responseData.unloading[i].grade_GL,
                    unloading_weight: responseData.unloading[i].unloading_weight,
                    withered_pct: responseData.unloading[i].withered_pct,
                    batch_no: responseData.unloading[i].BatchBatchNo,
                    date: responseData.unloading[i].date,
                }

                unloadingArray.push(unloading);
            }
            setLoftRoomUnloading(unloadingArray);
        } catch (err) {
        }
    };

    const fetchRolling = async () => {
        let rolling;
        let rollingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/rolling/reports/rollings`
            );
            for (let i = 0; i < responseData.rolling.length; i++) {
                rolling = {
                    batch_no: responseData.rolling[i].BatchBatchNo,
                    grade_GL: responseData.rolling[i].grade_GL,
                    roller_no: responseData.rolling[i].RollerRollerId,
                    roller_turn: responseData.rolling[i].rolling_turn,
                    roller_input: responseData.rolling[i].rolling_in_kg,
                    roller_out: responseData.rolling[i].rolling_out_kg,
                    date: responseData.rolling[i].batch_date,
                }
                rollingArray.push(rolling);
            }
            setRolling(rollingArray);

        } catch (err) {
        }
    };
    const fetchRollBreaking = async () => {
        let rbreaking;
        let rbreakingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/rolling/reports/rbreakings`
            );
            for (let i = 0; i < responseData.rollBreaking.length; i++) {
                rbreaking = {
                    batch_no: responseData.rollBreaking[i].BatchBatchNo,
                    grade_GL: responseData.rollBreaking[i].grade_GL,
                    rollBreaker_no: responseData.rollBreaking[i].RollBreakerRollBreakerId,
                    dhool_no: responseData.rollBreaking[i].rolling_turn,
                    rbreaker_input_weight: responseData.rollBreaking[i].rolling_out_kg,
                    rbreaker_out_weight: responseData.rollBreaking[i].dhool_out_weight,
                    dhool_pct: Math.round((responseData.rollBreaking[i].dhool_pct) * 100) / 100,
                    date: responseData.rollBreaking[i].batch_date,
                }
    
                rbreakingArray.push(rbreaking);
            }
            setRollBreaking(rbreakingArray);
        } catch (err) {
        }
    };
    const fetchFermenting = async () => {
        let fermenting;
        let fermentingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/rolling/reports/fermentings`
            );
            for (let i = 0; i < responseData.fermenting.length; i++) {
                fermenting = {
                    batch_no: responseData.fermenting[i].BatchBatchNo,
                    grade_GL: responseData.fermenting[i].grade_GL,
                    dhool_no: responseData.fermenting[i].rolling_turn,
                    fermenting_input_weight: responseData.fermenting[i].dhool_out_weight,
                    fermenting_out_weight: responseData.fermenting[i].fd_out_kg,
                    fermenting_pct: Math.round((responseData.fermenting[i].fd_pct) * 100) / 100,
                    date: responseData.fermenting[i].batch_date,
                }

                fermentingArray.push(fermenting);
            }
            setFermenting(fermentingArray);

        } catch (err) {
        }
    };
    const fetchDrying = async () => {
        let drying;
        let dryingArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/rolling/reports/dryings`
            );
            for (let i = 0; i < responseData.drying.length; i++) {
                drying = {
                    batch_no: responseData.drying[i].BatchBatchNo,
                    grade_GL: responseData.drying[i].grade_GL,
                    dhool_no: responseData.drying[i].rolling_turn,
                    drying_input_weight: responseData.drying[i].fd_out_kg,
                    drying_out_weight: responseData.drying[i].drier_out_kg,
                    date: responseData.drying[i].batch_date,
                }
                console.log(rolling);
                dryingArray.push(drying);
            }
            setDrying(dryingArray);

        } catch (err) {
        }
    };


    const fetchOutput = async () => {
        let output;
        let outputArray = [];
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/rolling/reports/outturn`
            );
            for (let i = 0; i < responseData.dhools.length; i++) {
                output = {
                    batch_no: responseData.dhools[i].batch_no,
                    leafGrade: responseData.dhools[i].leafGrade,
                    outturn: responseData.dhools[i].outturn,
                    batch_date: responseData.dhools[i].batch_date,
                }
  
                outputArray.push(output);
            }
            console.log(outputArray);
            setOutput(outputArray);


        } catch (err) {
        }
    };

    const dateHandler = async (event) => {
        event.preventDefault();

        if (selectedStage === 'Loft') {

            let loading = {};
            let loadingArray = [];
            let unloading;
            let unloadingArray = [];
            let starting = {};
            let startingArray = [];
            let mixing = {};
            let mixingArray = [];
            let finishing = {};
            let finishingArray = [];

            try {
                let responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/loft/reports/loadings/${startDate}`
                );

                for (let i = 0; i < responseData.loading.length; i++) {
                    loading = {
                        trough_no: responseData.loading[i].BoxBoxId[1],
                        box_no: responseData.loading[i].BoxBoxId[3],
                        grade_GL: responseData.loading[i].grade_GL,
                        net_weight: responseData.loading[i].total_Net_weight,
                        date: responseData.loading[i].date,
                    }
    
                    loadingArray.push(loading);
                }

  
                setLoftRoomLoading(loadingArray);
        

                responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/loft/reports/startings/${startDate}`
                );
        

                for (let i = 0; i < responseData.starting.length; i++) {
                    starting = {
                        trough_no: responseData.starting[i].TroughTroughId,
                        temp: responseData.starting[i].temperature,
                        humidity: responseData.starting[i].humidity,
                        date: responseData.starting[i].date.slice(0, 10),
                        time: responseData.starting[i].date.slice(11, 19),
                    }
                    startingArray.push(starting);
                }
                setLoftRoomStarting(startingArray);

                responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/loft/reports/unloadings/${startDate}`
                );
                console.log(responseData.unloading);
                for (let i = 0; i < responseData.unloading.length; i++) {
                    unloading = {
                        trough_no: responseData.unloading[i].box_id[1],
                        box_no: responseData.unloading[i].box_id[3],
                        grade_GL: responseData.unloading[i].grade_GL,
                        unloading_weight: responseData.unloading[i].unloading_weight,
                        withered_pct: responseData.unloading[i].withered_pct,
                        batch_no: responseData.unloading[i].BatchBatchNo,
                        date: responseData.unloading[i].date,
                    }
          
                    unloadingArray.push(unloading);
                }
                setLoftRoomUnloading(unloadingArray);



                responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/loft/reports/mixings/${startDate}`
                );
        

                for (let i = 0; i < responseData.mixing.length; i++) {
                    mixing = {
                        trough_no: responseData.mixing[i].TroughTroughId,
                        turn: responseData.mixing[i].ProcessProcessName.slice(6),
                        temp: responseData.mixing[i].temperature,
                        humidity: responseData.mixing[i].humidity,
                        date: responseData.mixing[i].date.slice(0, 10),
                        time: responseData.mixing[i].date.slice(11, 19),
                    }
          
                    mixingArray.push(mixing);
                }
                setLoftRoomMixing(mixingArray);

                responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/loft/reports/finishings/${startDate}`
                );
                console.log(responseData.finishing);

                for (let i = 0; i < responseData.finishing.length; i++) {
                    finishing = {
                        trough_no: responseData.finishing[i].TroughTroughId,
                        temp: responseData.finishing[i].temperature,
                        humidity: responseData.finishing[i].humidity,
                        date: responseData.finishing[i].date.slice(0, 10),
                        time: responseData.finishing[i].date.slice(11, 19),
                    }
            
                    finishingArray.push(finishing);
                }
                setLoftRoomFinishing(finishingArray);

            } catch (err) {
            }


        } else if (selectedStage === 'Rolling') {
            let rolling;
            let rollingArray = [];
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/reports/rollingss/${startDate}`
                );
                for (let i = 0; i < responseData.rolling.length; i++) {
                    rolling = {
                        batch_no: responseData.rolling[i].BatchBatchNo,
                        grade_GL: responseData.rolling[i].grade_GL,
                        roller_no: responseData.rolling[i].RollerRollerId,
                        roller_turn: responseData.rolling[i].rolling_turn,
                        roller_input: responseData.rolling[i].rolling_in_kg,
                        roller_out: responseData.rolling[i].rolling_out_kg,
                        date: responseData.rolling[i].batch_date,
                    }
    
                    rollingArray.push(rolling);
                }
                setRolling(rollingArray);


            } catch (err) {
            }
        } else if (selectedStage === 'RBreaking') {
            let rbreaking;
            let rbreakingArray = [];
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/reports/rbreakingss/${startDate}`
                );
                for (let i = 0; i < responseData.rollBreaking.length; i++) {
                    rbreaking = {
                        batch_no: responseData.rollBreaking[i].BatchBatchNo,
                        grade_GL: responseData.rollBreaking[i].grade_GL,
                        rollBreaker_no: responseData.rollBreaking[i].RollBreakerRollBreakerId,
                        dhool_no: responseData.rollBreaking[i].rolling_turn,
                        rbreaker_input_weight: responseData.rollBreaking[i].rolling_out_kg,
                        rbreaker_out_weight: responseData.rollBreaking[i].dhool_out_weight,
                        dhool_pct: Math.round((responseData.rollBreaking[i].dhool_pct) * 100) / 100,
                        date: responseData.rollBreaking[i].batch_date,
                    }

                    rbreakingArray.push(rbreaking);
                }
                setRollBreaking(rbreakingArray);


            } catch (err) {
            }
        } else if (selectedStage === 'Fermenting') {
            let fermenting;
            let fermentingArray = [];
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/reports/fermentingss/${startDate}`
                );
                for (let i = 0; i < responseData.fermenting.length; i++) {
                    fermenting = {
                        batch_no: responseData.fermenting[i].BatchBatchNo,
                        grade_GL: responseData.fermenting[i].grade_GL,
                        dhool_no: responseData.fermenting[i].rolling_turn,
                        fermenting_input_weight: responseData.fermenting[i].dhool_out_weight,
                        fermenting_out_weight: responseData.fermenting[i].fd_out_kg,
                        fermenting_pct: Math.round((responseData.fermenting[i].fd_pct) * 100) / 100,
                        date: responseData.fermenting[i].batch_date,
                    }
        
                    fermentingArray.push(fermenting);
                }
                setFermenting(fermentingArray);


            } catch (err) {
            }
        } else if (selectedStage === 'Drying') {
            let drying;
            let dryingArray = [];
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/reports/dryingss/${startDate}`
                );
                for (let i = 0; i < responseData.drying.length; i++) {
                    drying = {
                        batch_no: responseData.drying[i].BatchBatchNo,
                        grade_GL: responseData.drying[i].grade_GL,
                        dhool_no: responseData.drying[i].rolling_turn,
                        drying_input_weight: responseData.drying[i].fd_out_kg,
                        drying_out_weight: responseData.drying[i].drier_out_kg,
                        date: responseData.drying[i].batch_date,
                    }
                    console.log(rolling);
                    dryingArray.push(drying);
                }
                setDrying(dryingArray);


            } catch (err) {
            }
        } else if (selectedStage === 'Output') {
            let output;
            let outputArray = [];
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/rolling/reports/outturnss/${startDate}`
                );
                for (let i = 0; i < responseData.dhools.length; i++) {
                    output = {
                        batch_no: responseData.dhools[i].batch_no,
                        leafGrade: responseData.dhools[i].leafGrade,
                        outturn: responseData.dhools[i].outturn,
                        batch_date: responseData.dhools[i].batch_date,
                    }
    
                    outputArray.push(output);
                }
                console.log(outputArray);
                setOutput(outputArray);


            } catch (err) {
            }
        }

    };


    return (
        <Grid  className={classes.background}
            direction={"column"}>
            <div className={classes.root}>
                <ErrorModal error={error} onClear={clearError} />
                <Grid container spacing={2} direction={"column"}>
                    <Grid item md>
                        <Typography align={'center'} variant={'h2'}>Production</Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.breadcrumbsContent}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="/" onClick={(event) => handleClick(event, 'Loft')}>
                                    <Typography variant={'h6'}>Loft Room</Typography>
                                    </Link>
                                <Link color="inherit" href="/" onClick={(event) => handleClick(event, 'Rolling')}>
                                    <Typography variant={'h6'}>Rolling Room</Typography>
                                    </Link>
                                <Link color="inherit" href="/" onClick={(event) => handleClick(event, 'RBreaking')}>
                                    <Typography variant={'h6'}>Roll Breaking Room</Typography>
                                    </Link>
                                <Link color="inherit" href="/"
                                    onClick={(event) => handleClick(event, 'Fermenting')}>
                                    <Typography variant={'h6'}>Fermenting Room</Typography>
                                    </Link>
                                <Link color="inherit" href="/" onClick={(event) => handleClick(event, 'Drying')}>
                                    <Typography variant={'h6'}>Drying Room</Typography>
                                    </Link>
                                <Link color="inherit" href="/" onClick={(event) => handleClick(event, 'Output')}>
                                    <Typography variant={'h6'}>Outturn</Typography>
                                    </Link>
                            </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.searchBox}>
                            <form onSubmit={dateHandler} className={"date-picker_form"}>

                                <div style={{ color: "black", marginBottom: "0.5rem" }}><Typography variant={'h6'} >Pick a Date : </Typography></div>

                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />


                                <Button type="submit">
                                    SEARCH
                                </Button>


                            </form>
                        </div>
                    </Grid>
                    {selectedStage === 'Loft' ?
                        <LoftRoom Loading={isLoading} loading={loftRoomLoading} starting={loftRoomStarting}
                            mixing={loftRoomMixing} finishing={loftRoomFinishing}
                            unloading={loftRoomUnloading} /> : null}
                    {selectedStage === 'Rolling' ? <Rolling Loading={isLoading} rollings={rolling} /> : null}
                    {selectedStage === 'RBreaking' ? <RollBreaking Loading={isLoading} rbreaking={rollBreaking} /> : null}
                    {selectedStage === 'Fermenting' ? <Fermenting Loading={isLoading} fermenting={fermenting} /> : null}
                    {selectedStage === 'Drying' ? <Drying Loading={isLoading} drying={drying} /> : null}
                    {selectedStage === 'Output' ? <Output Loading={isLoading} output={output} /> : null}
                </Grid>
            </div>
        </Grid>
    );
};

export default Production;
