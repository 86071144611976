import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Grid from "@material-ui/core/Grid";
import Report from "../components/Report";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "../../shared/components/FormElements/Button";
import './SupplierSelection.css';
import Input from "../../shared/components/FormElements/Input";
import { useForm } from "../../shared/hooks/form-hook";
import backg from '../../assets/white_back_e.jpg';

const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${backg})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y",
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
        minHeight: "70rem",
    },
    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "20em",
        background: " rgb(0, 0, 0, 0.3)"
    },
    root: {
        marginLeft: "15em",
        marginRight: "15em",
        marginBottom: "5em",
    },
    searchBox: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: "5em",
        
    },
    tableContainer:{
        marginBottom:"5em"
    }

}));

const Purchasing = () => {

    const classes = useStyles();

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedSuppliers, setLoadedSuppliers] = useState([]);
    const [formState, inputHandler] = useForm(
        {
            id: {
                value: '',
                isValid: false
            },
            name: {
                value: '',
                isValid: false
            },
            type: {
                value: '',
                isValid: false
            }
        },
        false
    );

    useEffect(() => {
        const fetchSuppliers = async () => {

            try {

                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/suppliers`
                );

                setLoadedSuppliers(responseData.suppliers);


            } catch (err) {
            }
        };

        fetchSuppliers();

    }, [sendRequest]);

    const supplierSelectHandler = async (event) => {
        event.preventDefault();
        console.log(formState.inputs);

        if (formState.inputs.id.value !== '' && formState.inputs.name.value !== '' && formState.inputs.type.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.supplier_id === formState.inputs.id.value && supp.name === formState.inputs.name.value && supp.type === formState.inputs.type.value)
            );

        } else if (formState.inputs.name.value !== '' && formState.inputs.id.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.name === formState.inputs.name.value && supp.supplier_id === formState.inputs.id.value)
            );
        } else if (formState.inputs.type.value !== '' && formState.inputs.id.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.type === formState.inputs.type.value && supp.supplier_id === formState.inputs.id.value)
            );
        } else if (formState.inputs.type.value !== '' && formState.inputs.name.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.type === formState.inputs.type.value && supp.name === formState.inputs.name.value)
            );
        } else if (formState.inputs.type.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.type === formState.inputs.type.value)
            );
        } else if (formState.inputs.id.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.supplier_id === formState.inputs.id.value)
            );
        } else if (formState.inputs.name.value !== '') {
            setLoadedSuppliers(prevSupplier =>
                prevSupplier.filter(supp => supp.name === formState.inputs.name.value)
            );
        }

    };
    const supplierResetHandler = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/supp/suppliers`
            );

            setLoadedSuppliers(responseData.suppliers);

        } catch (err) {
        }
    }

    return (
        <React.Fragment>
            <Grid className={classes.background}
                direction={"column"}>
                <div className={classes.root}>
                    <Grid container direction={"column"} spacing={3}>
                        <ErrorModal error={error} onClear={clearError} />

                        <Grid item>
                            <Typography align={'center'} variant={'h2'}>Purchasing</Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.searchBox}>
                                <form onSubmit={supplierSelectHandler} className={"supplier-profile-item__content"}>

                                    <Input
                                        id="id"
                                        element="input"
                                        type="text"
                                        label="Supplier ID :"
                                        validators={[]}
                                        onInput={inputHandler}

                                    />
                                    <Input
                                        id="name"
                                        element="input"
                                        type="text"
                                        label="Supplier Name :"
                                        validators={[]}

                                        onInput={inputHandler}
                                    />
                                    <Input
                                        id="type" element="dropdown" label="Supplier Type :" onInput={inputHandler}
                                        dropdownItems={["Grower Direct", "Grower through Agent", "Dealer"]} validators={[]}
                                    />
                                    <Button type="submit">
                                        SEARCH
                                </Button>
                                    <Button onClick={supplierResetHandler}>
                                        RESET
                                </Button>


                                </form>
                            </div>
                        </Grid>
                        <Grid item className={classes.tableContainer}>
                            {isLoading && (
                                <div className="center">
                                    <LoadingSpinner />
                                </div>
                            )}
                            {loadedSuppliers.length === 0 ? <Typography align={'center'} variant={'h4'}>No Such Suppliers !!!</Typography> : !isLoading && loadedSuppliers && <Report items={loadedSuppliers}
                                header={['ID', 'Supplier Name', 'Type', 'Status']} />}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </React.Fragment>
    );
};

export default Purchasing;
