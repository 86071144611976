import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import {VALIDATOR_REQUIRE} from '../../shared/util/validators';
import {useForm} from '../../shared/hooks/form-hook';
import './MachineForm.css';
import {AuthContext} from "../../shared/context/auth-context";
import {useHttpClient} from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import qs from 'query-string';
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.9",
    },
}));


const UpdateTrough = (props) => {

    const parsed = qs.parse(window.location.search);
    const auth = useContext(AuthContext);
    const id = useParams().id;
    const type = useParams().type;
    const classes = useStyles();
    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [loadedMachine, setLoadedMachine] = useState();
    const history = useHistory();

    const [formState, inputHandler, setFormData] = useForm(
        {
            troughtype: {
                value: '',
                isValid: false
            },
            capacity: {
                value: '',
                isValid: false
            },
        },
        false
    );


    useEffect(() => {
        const fetchMachine = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/machine/machine?id=${id}&type=${type}`
                );
                console.log(responseData.machine);

                setLoadedMachine(responseData.machine[0]);
                setFormData(
                    {
                        id: {
                            value: responseData.machine[0].id,
                            isValid: true
                        },
                        troughtype: {
                            value: responseData.machine[0].type,
                            isValid: true
                        },
                        capacity: {
                            value: responseData.machine[0].capacity,
                            isValid: true
                        },
                    },
                    true
                );
            } catch (err) {
            }
        };
        fetchMachine();

    }, [sendRequest, setFormData, parsed.machine_id, parsed.type]);

    const machineUpdateSubmitHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(
                `${process.env.REACT_APP_URL}/machine/machines/${parsed.type}`,
                'PATCH',
                JSON.stringify({
                    trough_id: formState.inputs.id.value,
                    troughtype: formState.inputs.type.value,
                    capacity: formState.inputs.capacity.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                }
            );
            history.push('/machines');
        } catch (err) {
        }
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner/>
            </div>
        );
    }

    if (!loadedMachine && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find supplier!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError}/>
            {!isLoading && loadedMachine && (
                <Grid container alignItems={"center"} justify={"center"} className={classes.background}
                      direction={"column"}>
                    <Grid item>
                        <form className="trough-update-form" onSubmit={machineUpdateSubmitHandler}>
                            <h2 style={{color: "grey", textAlign: "center"}}>Edit Trough</h2>
                            <hr style={{height: "0.15rem", color: "black", backgroundColor: "grey"}}/>
                            <Grid container justify={"space-between"} alignItems={"baseline"}>

                                <Input
                                    id="type"
                                    element="input"
                                    type="text"
                                    label="Trough type :"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a valid trough type."
                                    onInput={inputHandler}
                                    initialValue={loadedMachine.type}
                                    initialValid={true}
                                />
                                <Input
                                    id="capacity"
                                    element="input"
                                    type="text"
                                    label="Capacity :"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter a valid capacity."
                                    onInput={inputHandler}
                                    initialValue={loadedMachine.capacity}
                                    initialValid={true}
                                />
                            </Grid>

                            <Grid container justify={"space-between"} direction={"column"} alignItems={"center"}>
                                <Grid item>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        UpdateTrough
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default UpdateTrough;
