import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_PHONE } from '../../shared/util/validators';
import Typography from "@material-ui/core/Typography";
import { useForm } from '../../shared/hooks/form-hook';
import './SupplierForm.css';
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import background from '../../assets/white_back_e.jpg';
import { notify } from "react-notify-toast";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import Avatar from "@material-ui/core/Avatar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.8"
    },
    back: {
        backgroundColor: "rgba(180, 235, 52, 0.6)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.95"
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginLeft: "auto",
        marginRight: "auto"
    },

}));
const UpdateSupplier = () => {
    const auth = useContext(AuthContext);
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedSupplier, setLoadedSupplier] = useState();
    const [image, setImage] = useState(null);
    const history = useHistory();
    const suppId = useParams().suppId;
    const classes = useStyles();

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            telephone_no: {
                value: '',
                isValid: false
            },
            address: {
                value: '',
                isValid: false
            },
            status: {
                value: '',
                isValid: false
            },
            type: {
                value: '',
                isValid: false
            },
            image: {
                value: null,
                isValid: false
            }
        },
        false
    );


    useEffect(() => {
        const fetchSupplier = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/suppliers/${suppId}`
                );
                console.log(responseData.supplier);

                setLoadedSupplier(responseData.supplier[0]);

                setImage(`${process.env.REACT_APP_URL}/${responseData.supplier[0].image}`);
                setFormData(
                    {
                        name: {
                            value: responseData.supplier[0].name,
                            isValid: true
                        },
                        telephone_no: {
                            value: responseData.supplier[0].telephone_no,
                            isValid: true
                        },
                        address: {
                            value: responseData.supplier[0].address,
                            isValid: true
                        },
                        status: {
                            value: responseData.supplier[0].status,
                            isValid: true
                        },
                        type: {
                            value: responseData.supplier[0].type,
                            isValid: true
                        },
                        date_joined: {
                            value: responseData.supplier[0].date_joined,
                            isValid: true
                        },
                        image: {
                            value: responseData.supplier[0].image,
                            isValid: true
                        }
                    },
                    true
                );
                setStartDate(new Date(responseData.supplier[0].date_joined));
            } catch (err) {
            }
        };
        fetchSupplier();

    }, [sendRequest, setFormData, suppId]);

    const placeUpdateSubmitHandler = async event => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('supplier_id', suppId);
            formData.append('name', formState.inputs.name.value);
            formData.append('type', formState.inputs.type.value);
            formData.append('telephone_no', formState.inputs.telephone_no.value);
            formData.append('address', formState.inputs.address.value);
            formData.append('status', formState.inputs.status.value);
            formData.append('date_joined', startDate);
            formData.append('image', formState.inputs.image === undefined ? null : formState.inputs.image.value);
            formData.append('type', formState.inputs.type.value);

            await sendRequest(
                `${process.env.REACT_APP_URL}/supp/suppliers`,
                'PATCH',
                formData,
                {
                    Authorization: 'Bearer ' + auth.token
                }
            );
            notify.show('Supplier is updated successfully', 'warning', 4000);

            history.push('/suppliers');
        } catch (err) {
        }
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedSupplier && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find supplier!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Grid container alignItems={"center"} justify={"center"} direction={"column"}
                className={classes.background}>

                <Grid item>
                    <ErrorModal error={error} onClear={clearError} />

                    {!isLoading && loadedSupplier && (
                        <form className="supplier-form" onSubmit={placeUpdateSubmitHandler}>
                            <h2 style={{ color: "grey", textAlign: "center" }}>Edit Supplier</h2>
                            <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                            <Avatar src={image} alt={loadedSupplier.name} className={classes.avatar} />

                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <Input id="type" element="dropdown" label="Supplier Type : "
                                        onInput={inputHandler}
                                        dropdownItems={["Grower Direct", "Grower through Agent", "Dealer"]}
                                        initialValue={loadedSupplier.type}
                                        initialValid={true}
                                        validators={[]} />
                                </Grid>
                                <Grid item>
                                    <Input id="status" element="dropdown" label="Status :" onInput={inputHandler}
                                        dropdownItems={["Active", "Inactive",]}
                                        validators={[]}
                                        initialValue={loadedSupplier.status}
                                        initialValid={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <Input
                                        id="name"
                                        element="input"
                                        label="Name :"
                                        validators={[VALIDATOR_REQUIRE()]}
                                        errorText="Please enter a valid name."
                                        onInput={inputHandler}
                                        initialValue={loadedSupplier.name}
                                        initialValid={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid item>
                                        <Input
                                            id="telephone_no"
                                            element="input"
                                            label="Telephone Number :"
                                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_PHONE()]}
                                            errorText="Please enter a valid telephone number."
                                            onInput={inputHandler}
                                            initialValue={loadedSupplier.telephone_no}
                                            initialValid={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Input
                                id="address"
                                element="textarea"
                                label="Address :"
                                validators={[VALIDATOR_MINLENGTH(5)]}
                                errorText="Please enter a valid address."
                                onInput={inputHandler}
                                initialValue={loadedSupplier.address}
                                initialValid={true}
                            />


                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <ImageUpload
                                        id="image"
                                        onInput={inputHandler}
                                        middleText={"Change the profile image."}
                                    />
                                </Grid>
                                <Grid item>
                                    <div style={{ color: "black", marginBottom: "1rem" }}>
                                        <Typography variant={'h6'}>
                                            Date Joined:
                                        </Typography>
                                    </div>
                                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                                </Grid>
                            </Grid>
                            <Grid container justify={"space-between"} direction={"column"} alignItems={"center"} style={{marginTop:"3rem"}}>
                                <Grid item>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        UPDATE SUPPLIER
                                </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default UpdateSupplier;
