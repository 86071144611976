import React from 'react';
import { Pie } from 'react-chartjs-2';

function PieChart(props) {
    let labels = [];
    let values = [];

    props.data.map((element) => {
        labels.push(element.SupplierSupplierId);
        values.push(element.totalNetWeight);
    });
    console.log(props.data);

    return (
        <React.Fragment>
            <Pie data={{
                labels: labels,
                datasets: [
                    {
                        label: 'Total net weight',
                        data: values,
                        backgroundColor: [
                            'rgb(255, 99, 132 )',
                            'rgb(54, 162, 235 )',
                            'rgb(255, 206, 86 )',
                            'rgb(75, 192, 192 )',
                            'rgb(153, 102, 255)',
                            'rgb(255, 159, 64)'
                        ],
                        borderColor: [
                            'white'
                        ],
                        borderWidth: 1

                    }

                ]
            }} height={400} width={600} options={{
                maintainAspectRatio: false, tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                            let total = meta.total;
                            let currentValue = dataset.data[tooltipItem.index];
                            let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                            return currentValue + ' (' + percentage + '%)';
                        },
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        }
                    }
                }
            }} />
        </React.Fragment>
    );
}

export default PieChart;