import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginBottom: 10
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const ProductionReport = (props) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} elevation={5} square={false}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {props.header.map((col) => (
                            <StyledTableCell align="center">{col}</StyledTableCell>
                        ))}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map((row) => {

                        return (
                            <StyledTableRow key={row.a}>


                                {props.header.map((col, i) => (


                                    <StyledTableCell align="center" key={i} component="th" scope="row">
                                        {row[Object.keys(row)[i]]}
                                    </StyledTableCell>

                                ))}
                            </StyledTableRow>);

                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default ProductionReport;