import React, { useContext, useEffect, useState } from "react";
import Button from '../../shared/components/FormElements/Button';
import Card from "../../shared/components/UIElements/Card";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Avatar from "../../shared/components/UIElements/Avatar";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import './UserProfile.css'
import Grid from "@material-ui/core/Grid";
import PhoneIcon from '@material-ui/icons/Phone';
import HouseIcon from '@material-ui/icons/House';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { notify } from "react-notify-toast";
import Modal from '../../shared/components/UIElements/Modal';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TodayIcon from '@material-ui/icons/Today';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import CardContent from '@material-ui/core/CardContent';

const UserProfile = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedUser, setLoadedUser] = useState();

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);

    };

    const cancelDeleteHandler = () => {
        setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);
        try {
            await sendRequest(
                `${process.env.REACT_APP_URL}/auth/users/${loadedUser.user_id}`,
                'DELETE',
                null,
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                }
            );
            notify.show('User is deleted successfully', 'error', 4000);
        } catch (err) {
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/auth/users/${auth.userId}`
                );
                console.log(responseData.user[0].user_id);
                setLoadedUser(responseData.user[0]);
                console.log(loadedUser.name);
            } catch (err) {
            }
        };
        fetchUsers();

    }, [sendRequest, auth.userId]);

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedUser && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find user!</h2>
                </Card>
            </div>
        );
    }
    return (
        <div >
            <Grid container direction="column" justify="center" alignItems="center" style={{ height: "50em" }} className="backgroundd-image">
                <Grid item>
                    <ErrorModal error={error} onClear={clearError} />
                    <Modal
                        show={showConfirmModal}
                        onCancel={cancelDeleteHandler}
                        header="Are you sure?"
                        footerClass="user-item__modal-actions"
                        footer={
                            <React.Fragment>
                                <Button inverse onClick={cancelDeleteHandler}>
                                    CANCEL
                            </Button>
                                <Button danger onClick={confirmDeleteHandler}>
                                    DELETE
                            </Button>
                            </React.Fragment>
                        }
                    >
                        <p>
                            Do you want to proceed and delete this User? <br />
                            Please note that it can't be undone thereafter.
                        </p>
                    </Modal>

                    <Card className="user-profile-item__content">
                        {isLoading && <LoadingSpinner asOverlay />}

                        <div className="user-profile-item__image">
                            {loadedUser.image ? <Avatar image={`${process.env.REACT_APP_URL}/${loadedUser.image}`}
                                alt={loadedUser.name} /> :
                                <Avatar image={loadedUser.image} alt={loadedUser.name} />}
                        </div>

                        <CardContent>
                            <div className="user-profile-item__info">
                                <h1>{loadedUser.name}</h1>
                                <h2><AccountCircleIcon /> {loadedUser.user_id}</h2>
                                <h2><StarsRoundedIcon /> {loadedUser.user_type}</h2>
                                <h3><SubtitlesIcon /> {loadedUser.nic}</h3>
                                <h3><PhoneIcon /> {loadedUser.telephone_no}</h3>
                                <h3><TodayIcon /> {loadedUser.dob}</h3>
                                <h3><AlternateEmailIcon /> {loadedUser.email}</h3>
                                <h3><HouseIcon /> {loadedUser.address}</h3>

                                <div className="user-profile-item__actions">
                                    <Button to={`/users/${loadedUser.user_id}`}>EDIT</Button>
                                    {auth.userType !== "Admin" ?
                                        <Button danger onClick={showDeleteWarningHandler}>
                                            DELETE
                            </Button> : null}
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
export default UserProfile;
