import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useHttpClient} from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "../../shared/components/FormElements/Button";
import './SupplierSelection.css';
import Input from "../../shared/components/FormElements/Input";
import {useForm} from "../../shared/hooks/form-hook";
import backg from '../../assets/white_back_e.jpg';
import ProductionReport from "../components/ProductionReport";

const useStyles = makeStyles(theme => ({

    background: {
        backgroundImage: `url(${backg})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat-y",
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
        minHeight: "70rem",
    },
    card: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "5em 2em",
        width: "30em",
        marginBottom: "20em",
        background: " rgb(0, 0, 0, 0.3)"
    },
    root: {
        marginLeft: "15em",
        marginRight: "15em",
        marginBottom: "5em",
    },
    searchBox: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: "5em",

    },
    tableContainer: {
        marginBottom: "5em"
    }


}));

const AgentPurchasing = () => {

    const classes = useStyles();
    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [loadedPurchasing, setLoadedPurchasing] = useState([]);
    const [formState, inputHandler] = useForm(
        {
            id: {
                value: '',
                isValid: false
            },
        },
        false
    );


    useEffect(() => {
        const fetchPurchasing = async () => {
            try {
                let agentSupplies = {};
                let agentSuppliesArray = [];

                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/supp/reports/agentsupplies`
                );
                console.log(responseData.suppliers);
                for (let i = 0; i < responseData.suppliers.length; i++) {
                    agentSupplies = {
                        agentID: responseData.suppliers[i].userId,
                        userName: responseData.suppliers[i].userName,
                        suppID: responseData.suppliers[i].suppId,
                        suppName: responseData.suppliers[i].suppName,
                        gradeGL: responseData.suppliers[i].grade_GL,
                        grossWeight: responseData.suppliers[i].gross_weight,
                        noOfCont: responseData.suppliers[i].no_of_container,
                        water: responseData.suppliers[i].water,
                        cLeaf: responseData.suppliers[i].course_leaf,
                        other: responseData.suppliers[i].other,
                        netWeight: responseData.suppliers[i].net_weight,
                        deduct: responseData.suppliers[i].deduction,
                        contType: responseData.suppliers[i].container_type,
                        isDeleted: responseData.suppliers[i].is_deleted === 1 ? "Yes" : "No",
                    }
                    agentSuppliesArray.push(agentSupplies);
                }

                setLoadedPurchasing(agentSuppliesArray);


            } catch (err) {
            }
        };

        fetchPurchasing();

    }, [sendRequest]);

    const supplierSelectHandler = async (event) => {
        event.preventDefault();
        console.log(formState.inputs);

        if (formState.inputs.id.value !== '') {
            setLoadedPurchasing(prevSupplier =>
                prevSupplier.filter(supp => supp.agentID === formState.inputs.id.value)
            );
        }



    };
    const supplierResetHandler = async () => {
        try {
            let agentSupplies = {};
            let agentSuppliesArray = [];

            const responseData = await sendRequest(
                `${process.env.REACT_APP_URL}/supp/reports/agentsupplies`
            );
            console.log(responseData.suppliers);
            for (let i = 0; i < responseData.suppliers.length; i++) {
                agentSupplies = {
                    agentID: responseData.suppliers[i].userId,
                    userName: responseData.suppliers[i].userName,
                    suppID: responseData.suppliers[i].suppId,
                    suppName: responseData.suppliers[i].suppName,
                    gradeGL: responseData.suppliers[i].grade_GL,
                    grossWeight: responseData.suppliers[i].gross_weight,
                    noOfCont: responseData.suppliers[i].no_of_container,
                    water: responseData.suppliers[i].water,
                    cLeaf: responseData.suppliers[i].course_leaf,
                    other: responseData.suppliers[i].other,
                    netWeight: responseData.suppliers[i].net_weight,
                    deduct: responseData.suppliers[i].deduction,
                    contType: responseData.suppliers[i].container_type,
                    isDeleted: responseData.suppliers[i].is_deleted === 1 ? "Yes" : "No",
                }
                agentSuppliesArray.push(agentSupplies);
            }

            setLoadedPurchasing(agentSuppliesArray);





        } catch (err) {
        }

    }
    return (
        <React.Fragment>
            <Grid className={classes.background}
                  direction={"column"}>
                <div className={classes.root}>
                    <Grid container direction={"column"} spacing={3}>
                        <ErrorModal error={error} onClear={clearError}/>

                        <Grid item>
                            <Typography align={'center'} variant={'h2'}>Agent Purchasing</Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.searchBox}>
                                <form onSubmit={supplierSelectHandler} className={"agent-supplier-profile-item__content"}>

                                    <Input
                                        id="id"
                                        element="input"
                                        type="text"
                                        label="Agent ID :"
                                        validators={[]}
                                        onInput={inputHandler}

                                    />

                                    <Button type="submit">
                                        SEARCH
                                    </Button>
                                    <Button onClick={supplierResetHandler}>
                                        RESET
                                    </Button>


                                </form>
                            </div>
                        </Grid>
                        <Grid item className={classes.tableContainer}>
                            {isLoading && (
                                <div className="center">
                                    <LoadingSpinner/>
                                </div>
                            )}
                            {loadedPurchasing.length === 0 ?
                                <Typography align={'center'} variant={'h4'}>No Such Agents Purchasing
                                    !!!</Typography> : !isLoading && loadedPurchasing &&
                                <ProductionReport items={loadedPurchasing}
                                        header={['Agent ID', 'Agent Name', 'Supplier ID', 'Supplier Name', 'Grade GL', 'Gross Weight', 'No of Containers', 'Water', 'Course Leaf', 'Other', 'Net Weight', 'Deductions', 'Container Type', 'Is Deleted']}/>}
                        </Grid>

                    </Grid>

                </div>
            </Grid>
        </React.Fragment>
    );
};

export default AgentPurchasing;
