import React from 'react';
import { Line} from "react-chartjs-2";

function LineChart(props) { 
    return (
        <React.Fragment>
            <Line data={{
                labels:props.data,
                datasets:[
                    {
                        label: 'Total outturn',
                        data: props.outturns,
                        backgroundColor: [
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderColor: [
                            'white'
                        ],
                        borderWidth: 2
                    }
                ]
            }} height={400} width={600} options={{maintainAspectRatio: false }}/>
        </React.Fragment>
    );
}

export default LineChart;