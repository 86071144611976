import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Card from '../../shared/components/UIElements/Card';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import {
    VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH, VALIDATOR_PHONE, VALIDATOR_EMAIL
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './UserForm.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.8"
    },
    back: {
        backgroundColor: "rgba(180, 235, 52, 0.6)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.95"
    },
}));

const UpdateUser = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedUser, setLoadedUser] = useState();
    const userId = useParams().userId;
    const history = useHistory();
    const [startDate, setStartDate] = useState(new Date());
    const classes = useStyles();

    const [formState, inputHandler, setFormData] = useForm(
        {
            user_id: {
                value: '',
                isValid: false
            },
            name: {
                value: '',
                isValid: false
            },
            email: {
                value: '',
                isValid: false
            },
            password: {
                value: '',
                isValid: false
            },

            dob: {
                value: '',
                isValid: false
            },
            telephone_no: {
                value: '',
                isValid: false
            },
            nic: {
                value: '',
                isValid: false
            },
            address: {
                value: '',
                isValid: false
            },
        },
        false
    );

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_URL}/auth/users/${userId}`
                );
                console.log(responseData.user[0].user_id);
                setLoadedUser(responseData.user[0]);
                setFormData(
                    {
                        user_id: {
                            value: responseData.user[0].user_id,
                            isValid: true
                        },
                        name: {
                            value: responseData.user[0].name,
                            isValid: true
                        },
                        email: {
                            value: responseData.user[0].email,
                            isValid: true
                        },

                        dob: {
                            value: responseData.user[0].dob,
                            isValid: true
                        },
                        telephone_no: {
                            value: responseData.user[0].telephone_no,
                            isValid: true
                        },
                        nic: {
                            value: responseData.user[0].nic,
                            isValid: true
                        },
                        address: {
                            value: responseData.user[0].address,
                            isValid: true
                        },
                    },
                    true
                );
                setStartDate(new Date(responseData.user[0].dob));

            } catch (err) {
            }
        };
        fetchUsers();

    }, [sendRequest, userId, setFormData]);

    const userUpdateSubmitHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(
                `${process.env.REACT_APP_URL}/auth/users`,
                'PATCH',
                JSON.stringify({
                    user_id: formState.inputs.user_id.value,
                    password: formState.inputs.password.value,
                    name: formState.inputs.name.value,
                    email: formState.inputs.email.value,
                    dob: formState.inputs.dob.value,
                    telephone_no: formState.inputs.telephone_no.value,
                    nic: formState.inputs.nic.value,
                    address: formState.inputs.address.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                }
            );
            history.push('/users');
        } catch (err) {
        }
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedUser && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find user!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>

            <Grid container alignItems={"center"} justify={"center"} direction={"column"}
                className={classes.background}>
                <Grid item>

                    <ErrorModal error={error} onClear={clearError} />
                    {!isLoading && loadedUser && (
                        <form className="user-form" onSubmit={userUpdateSubmitHandler}>
                            <h2 style={{ color: "grey", textAlign: "center" }}>Edit User</h2>
                            <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <Input
                                        id="name"
                                        element="input"
                                        label="Name :"
                                        type="text"
                                        validators={[VALIDATOR_REQUIRE()]}
                                        errorText="Please enter a valid name."
                                        onInput={inputHandler}
                                        initialValue={loadedUser.name}
                                        initialValid={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <Input
                                        id="nic"
                                        element="input"
                                        label="NIC :"
                                        type="text"
                                        validators={[VALIDATOR_MAXLENGTH(10), VALIDATOR_MINLENGTH(10)]}
                                        errorText="Please enter a valid NIC."
                                        onInput={inputHandler}
                                        initialValue={loadedUser.nic}
                                        initialValid={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <Input
                                        id="email"
                                        element="input"
                                        label="Email :"
                                        type="text"
                                        validators={[VALIDATOR_EMAIL()]}
                                        errorText="Please enter a valid email address."
                                        onInput={inputHandler}
                                        initialValue={loadedUser.email}
                                        initialValid={true}
                                    />

                                </Grid>
                                <Grid item>
                                    <Input
                                        id="telephone_no"
                                        element="input"
                                        label="Telephone Number :"
                                        type="text"
                                        validators={[VALIDATOR_PHONE()]}
                                        errorText="Please enter a valid telephone number."
                                        onInput={inputHandler}
                                        initialValue={loadedUser.telephone_no}
                                        initialValid={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify={"space-between"} alignItems={"baseline"}>
                                <Grid item>
                                    <Input
                                        element="input"
                                        id="password"
                                        type="password"
                                        label="Password :"
                                        validators={[VALIDATOR_MINLENGTH(4)]}
                                        errorText="Please enter a valid password, at least 4 characters."
                                        onInput={inputHandler}
                                        initialValid={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <div style={{ color: "black", marginBottom: "0.5rem" }}>
                                        <Typography variant={'h6'}>D.O.B : </Typography>
                                    </div>
                                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                                </Grid>
                            </Grid>
                            <Input
                                id="address"
                                element="textarea"
                                label="Address :"
                                validators={[VALIDATOR_MINLENGTH(5)]}
                                errorText="Please enter a valid address, at least 5 characters."
                                onInput={inputHandler}
                                initialValue={loadedUser.address}
                                initialValid={true}
                            />

                            <Grid container justify={"space-between"} direction={"column"} alignItems={"center"}>
                                <Grid item>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        UPDATE USER
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default UpdateUser;
