import React from 'react';
import {Bar} from "react-chartjs-2";

function BarChartROllerWise(props) {
    let labels = [];
    let valueA = [];
    let valueB = [];
    let valueC = [];

    props.data.map((element) => {
        labels.push(element.rollerId);
        valueA.push(element.a);
        valueB.push(element.b);
        valueC.push(element.c);
    });
    console.log(props.data);
    return (
        <React.Fragment>
            <Bar data={{
                labels:labels,
                datasets:[
                    {
                        label: 'Grade A',
                        data: valueA,
                        backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                        label: 'Grade B',
                        data: valueB,
                        backgroundColor: 'rgb(54, 162, 235)',
                    },
                    {
                        label: 'Grade C',
                        data: valueC,
                        backgroundColor: 'rgb(75, 192, 192)',
                    },
                ]
            }} height={400} width={600} options={{maintainAspectRatio: false }}/>
        </React.Fragment>
    );
}

export default BarChartROllerWise;