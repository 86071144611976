import React, { useContext } from 'react';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import { VALIDATOR_REQUIRE } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { AuthContext } from "../../shared/context/auth-context";
import { useHistory } from "react-router-dom";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import background from "../../assets/white_back_e.jpg";
import Grid from "@material-ui/core/Grid";
import './MachineForm.css';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.9",
    },
}));

const NewTrough = () => {
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler] = useForm(
        {
            type: {
                value: '',
                isValid: false
            },
            capacity: {
                value: '',
                isValid: false
            },
        },
        false
    );

    const history = useHistory();

    const machineSubmitHandler = async event => {
        event.preventDefault();
        console.log(formState.inputs); // send this to the backend!
        try {
            const formData = new FormData();

            formData.append('type', 'Trough');
            formData.append('troughtype', formState.inputs.type.value);
            formData.append('capacity', formState.inputs.capacity.value);
            formData.append('image', formState.inputs.image.value);


            await sendRequest(`${process.env.REACT_APP_URL}/machine/machine`, 'POST', formData, {
                Authorization: 'Bearer ' + auth.token
            });
            history.push('/machines');
        } catch (err) {
        } // send this to the backend!
    };

    return (

        <Grid container alignItems={"center"} justify={"center"} className={classes.background} direction={"column"}>
            <Grid item>
                <ErrorModal error={error} onClear={clearError} />
                <form className="trough-form" onSubmit={machineSubmitHandler}>
                    {isLoading && <LoadingSpinner asOverlay />}
                    <h2 style={{ color: "grey", textAlign: "center" }}>Register New Trough</h2>
                    <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                    <Grid container justify={"space-between"}>
                        <Grid item>
                            <Input
                                id="type"
                                element="input"
                                type="text"
                                label="Trough Type :"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid trough type."
                                onInput={inputHandler}
                            />

                            <Input
                                id="capacity"
                                element="input"
                                type="text"
                                label="Capacity :"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid capacity."
                                onInput={inputHandler}
                            />

                            <Button type="submit" disabled={!formState.isValid}>
                                ADD MACHINE
                </Button>

                        </Grid>
                        <Grid item>
                            <ImageUpload
                                id="image"
                                onInput={inputHandler}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default NewTrough;
