import React, { useContext, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_PHONE } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import './SupplierForm.css';
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { notify } from 'react-notify-toast';
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import background from '../../assets/white_back_e.jpg';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.9",
    },
    back: {
        backgroundColor: "rgba(55, 94, 13, 0.75)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        minHeight: "100vh",
        width: "100%",
        opacity: "0.95"
    }

}));
const NewSupplier = () => {
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const history = useHistory();
    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            type: {
                value: '',
                isValid: true
            },
            telephone_no: {
                value: '',
                isValid: false
            },
            address: {
                value: '',
                isValid: false
            },
            status: {
                value: '',
                isValid: true
            },

        },
        false
    );

    const supplierSubmitHandler = async event => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', formState.inputs.name.value);
            formData.append('type', formState.inputs.type.value);
            formData.append('telephone_no', formState.inputs.telephone_no.value);
            formData.append('address', formState.inputs.address.value);
            formData.append('status', formState.inputs.status.value);
            formData.append('date_joined', startDate);
            formData.append('image', formState.inputs.image === undefined ? null : formState.inputs.image.value);
            await sendRequest(`${process.env.REACT_APP_URL}/supp/supplier`, 'POST', formData, {
                Authorization: 'Bearer ' + auth.token
            });
            notify.show('Supplier is added successfully', 'success', 4000);
            history.push('/suppliers');
        } catch (err) {
        } // send this to the backend!
    };

    return (
        <React.Fragment>
            <Grid container alignItems={"center"} justify={"center"} className={classes.background}
                direction={"column"}>
                <Grid item>
                    <ErrorModal error={error} onClear={clearError} />
                    <form className="supplier-form" onSubmit={supplierSubmitHandler}>
                        {isLoading && <LoadingSpinner asOverlay />}
                        <h2 style={{ color: "grey", textAlign: "center" }}>Register New Supplier</h2>
                        <hr style={{ height: "0.15rem", color: "black", backgroundColor: "grey" }} />
                        <Input
                            id="name"
                            element="input"
                            label="Name : "
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
                            errorText="Please enter a valid name."
                            onInput={inputHandler}
                        />
                        <Input
                            id="telephone_no"
                            element="input"
                            label="Telephone Number : "
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_PHONE()]}
                            errorText="Please enter a valid telephone number."
                            onInput={inputHandler}
                        />
                        <Input
                            id="address"
                            element="textarea"
                            label="Address : "
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="Please enter a valid address."
                            onInput={inputHandler}
                        />
                        <Grid container justify={"space-between"} direction={"column"} alignItems={"center"}>
                            <Grid item>
                                <div style={{ color: "black", fontWeight: "Boldest" }}>
                                    <Typography style={{ display: "inline-block" }} variant={'h6'} >Date Joined : &nbsp;</Typography>
                                    <DatePicker style={{ display: "inline-block" }} selected={startDate} onChange={date => setStartDate(date)} />
                                </div>
                            </Grid>
                        </Grid>

                        <ImageUpload
                            id="image"
                            onInput={inputHandler}
                            center
                        />
                        <Grid container justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <Input id="type" element="dropdown" label="Supplier Type : " onInput={inputHandler}
                                    dropdownItems={["Grower Direct", "Grower through Agent", "Dealer"]}
                                    validators={[]} />
                            </Grid>
                            <Grid item>
                                <Input id="status" element="dropdown" label="Supplier Status : " onInput={inputHandler}
                                    dropdownItems={["Active", "Inactive",]}
                                    validators={[]} />
                            </Grid>
                        </Grid>
                        <Grid container justify={"space-between"} direction={"column"} alignItems={"center"}>
                            <Grid item>
                                <Button type="submit" disabled={!formState.isValid}>
                                    ADD SUPPLIER
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default NewSupplier;
