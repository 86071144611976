import React from 'react';
import './SupplierList.css';
import Card from '../../shared/components/UIElements/Card';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SupplierTable from "./SupplierTable";

const useStyles = makeStyles(theme => ({
    typoMargin: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },
        maxWidth: "30em",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2em",
        boxShadow: "0 1px 3px rgba(0, 0, 0)",
        borderRadius: "6px",
        background: "rgba(240, 255, 240, 0.8)",
        maxHeight: "5em"
    },
    SupplierContainer: {
        marginLeft: "5em",
        marginRight: "5em",
        
        [theme.breakpoints.down("md")]: {
            marginLeft: "1em",
            marginRight: "1em",
        }
    },
    tableContainer:{
        marginBottom:"3em",
        marginLeft:"auto",
        marginRight:"auto",
    },
    col:{
        border: "solid",
        color: "grey",
        borderWidth: "0.1em",
        marginBottom:"2em",
        width:"45em"
    }
}));
const SuppliersList = props => {
    const classes = useStyles();
    if (props.items.length === 0) {
        return (
            <div className="center">
                <Card>
                    <h2>No Suppliers found.</h2>
                </Card>
            </div>
        );


    }


    return (

        <div className='SupplierContainer-background'>   
            <div className={classes.SupplierContainer}>
                <div className={classes.typoMargin}>

                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Grower Direct
                    </Typography>

                </div>
                <hr className={classes.col} />


                {/*{props.direct.map(supp => (*/}
                    {/*    <SupplierItem*/}
                    {/*        key={supp.supplier_id}*/}
                    {/*        id={supp.supplier_id}*/}
                    {/*        name={supp.name}*/}
                    {/*        type={supp.type}*/}
                    {/*        telephone_no={supp.telephone_no}*/}
                    {/*        address={supp.address}*/}
                    {/*        status={supp.status}*/}
                    {/*        onDelete={props.onDeleteDirectSupplier}*/}
                    {/*    />*/}

                    {/*))}*/}
               <div className={classes.tableContainer}>
                        <SupplierTable rows={props.direct} onDelete={props.onDeleteDirectSupplier}/>
               </div>
                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Grower Through Agent
                    </Typography>
                </div>
                <hr className={classes.col} />
                <div className={classes.tableContainer}>
                    <SupplierTable rows={props.agent} onDelete={props.onDeleteAgentSupplier}/>
                </div>
                {/*<Grid container spacing={3}>*/}
                {/*    {props.agent.map(supp => (*/}
                {/*        <SupplierItem*/}
                {/*            key={supp.supplier_id}*/}
                {/*            id={supp.supplier_id}*/}
                {/*            name={supp.name}*/}
                {/*            type={supp.type}*/}
                {/*            telephone_no={supp.telephone_no}*/}
                {/*            address={supp.address}*/}
                {/*            status={supp.status}*/}
                {/*            onDelete={props.onDeleteAgentSupplier}*/}
                {/*        />*/}

                {/*    ))}*/}
                {/*</Grid>*/}
                <div className={classes.typoMargin}>
                    <Typography variant={"h3"} align={"center"} color={"primary"}>
                        Dealer
                    </Typography>
                </div>
                <hr className={classes.col} />
                <div className={classes.tableContainer}>
                    <SupplierTable rows={props.dealer} onDelete={props.onDeleteDealerSupplier}/>
                </div>
                {/*<Grid container spacing={3}>*/}
                {/*    {props.dealer.map(supp => (*/}
                {/*        <SupplierItem*/}
                {/*            key={supp.supplier_id}*/}
                {/*            id={supp.supplier_id}*/}
                {/*            name={supp.name}*/}
                {/*            type={supp.type}*/}
                {/*            telephone_no={supp.telephone_no}*/}
                {/*            address={supp.address}*/}
                {/*            status={supp.status}*/}
                {/*            onDelete={props.onDeleteDealerSupplier}*/}
                {/*        />*/}

                {/*    ))}*/}
                {/*</Grid>*/}
            </div>
        </div>

    );
};

export default SuppliersList;