import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProductionReport from "./ProductionReport";

const useStyles = makeStyles({

    tableContainer:{
        marginBottom:"5em"
    }
//

});

function RollBreaking(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid item>
                <Typography variant={'h4'}>Roll Breaking</Typography>
            </Grid>
            <Grid item className={classes.tableContainer}>
                {props.Loading && (
                    <div className="center">
                        <LoadingSpinner/>
                    </div>
                )}
                {!props.Loading && props.rbreaking && <ProductionReport items={props.rbreaking}
                                                          header={['Batch No', 'Leaf Grade', 'Roll Breaker No', 'Dhool No', 'Roll Breaker Input Weight (Kg)', 'Dhool Output Weight (Kg)','Dhool %','Date']}/>}
            </Grid>
        </React.Fragment>
    );
}

export default RollBreaking;